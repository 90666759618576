import type { TErrorInfo, TErrorMessage } from 'types';

export const prepareMessage = (message: TErrorMessage): string => {
  let formattedMessage = '';

  if (typeof message === 'object') {
    if (typeof message.msg === 'string') {
      formattedMessage = message.msg;
    } else {
      formattedMessage = JSON.stringify(message);
    }
  }

  if (typeof message === 'string') {
    formattedMessage = message;
  }

  return formattedMessage;
};

export const formatErrorForKitNotification = ({
  message,
  statusCode,
}: TErrorInfo): string[] => {
  const notification = [];

  if (statusCode) {
    notification.push(`Status code: ${statusCode}`);
  }

  if (message) {
    const preparedMessage = prepareMessage(message);

    notification.push(preparedMessage);
  }

  return notification;
};
