import { useCallback, useMemo } from 'react';

import { RiskEventActions } from 'pages/RiskEvent/context';

import type { TCurrentFCE, TUseLiquidationEstimateHeadProps } from '../types';

export const useLiquidationEstimateHead = ({
  dispatch,
  forceCloseEventsList,
  number,
}: TUseLiquidationEstimateHeadProps) => {
  const currentFCE = useMemo<TCurrentFCE>(() => {
    const index = forceCloseEventsList.findIndex(
      (item) => item.number === number,
    );

    return {
      index,
      clientID: forceCloseEventsList[index]?.clientId || '',
    };
  }, [forceCloseEventsList, number]);

  const handleMoveToPrevious = useCallback(() => {
    const prevNumber = forceCloseEventsList[currentFCE.index - 1]?.number || '';

    dispatch({
      type: RiskEventActions.SetForceCloseEventNumber,
      payload: prevNumber,
    });
  }, [currentFCE, forceCloseEventsList]);

  const handleMoveToNext = useCallback(() => {
    const nextNumber = forceCloseEventsList[currentFCE.index + 1]?.number || '';

    dispatch({
      type: RiskEventActions.SetForceCloseEventNumber,
      payload: nextNumber,
    });
  }, [currentFCE, forceCloseEventsList]);

  const handleClose = () => {
    dispatch({
      type: RiskEventActions.ResetForceCloseEventNumber,
    });
  };

  return {
    currentFCE,
    handleClose,
    handleMoveToNext,
    handleMoveToPrevious,
  };
};
