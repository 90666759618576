import { ICellValue, IColumn, formatDate } from 'react-ui-kit-exante';

import type { TForceCloseRule } from 'types';
import { transferValueToPercent } from 'utils';

import { DATE_TIME_WITH_SECONDS } from './constants';

export type TGetColumnsProps = {
  markOptions: Record<string, string>;
};

export const getColumns = ({
  markOptions,
}: TGetColumnsProps): IColumn<TForceCloseRule> => {
  return [
    {
      Header: 'Order',
      accessor: 'order',
      minWidth: 100,
      maxWidth: 125,
    },
    {
      Header: 'Rule Name',
      accessor: 'name',
      disableSortBy: true,
      tooltip: {
        type: 'custom',
        options: {
          Title: (value: string) => value || '',
        },
      },
      minWidth: 225,
      maxWidth: 250,
      Cell: ({ row: { original } }: ICellValue<TForceCloseRule>) =>
        original.name || '',
    },
    {
      Header: 'Mark Function',
      accessor: 'mark',
      disableSortBy: true,
      tooltip: {
        type: 'custom',
        options: {
          Title: (value: string) => markOptions[value] || '',
        },
      },
      minWidth: 100,
      maxWidth: 125,
      Cell: ({ row: { original } }: ICellValue<TForceCloseRule>) => {
        return original.mark || '';
      },
    },
    {
      Header: 'Context',
      accessor: 'mark_context',
      disableSortBy: true,
      minWidth: 225,
      maxWidth: 250,
      tooltip: {
        type: 'custom',
        options: {
          Title: (value: string) => value || '',
        },
      },
      Cell: ({ row: { original } }: ICellValue<TForceCloseRule>) =>
        original.mark_context || '',
    },
    {
      Header: 'Compare Function',
      accessor: 'func',
      disableSortBy: true,
      minWidth: 125,
      maxWidth: 150,
      Cell: ({ row: { original } }: ICellValue<TForceCloseRule>) =>
        original.func || '',
    },
    {
      Header: 'Rule Action',
      accessor: 'action',
      disableSortBy: true,
      minWidth: 100,
      maxWidth: 125,
      Cell: ({ row: { original } }: ICellValue<TForceCloseRule>) =>
        original.action || '',
    },
    {
      Header: 'Value',
      accessor: 'value',
      disableSortBy: true,
      tooltip: {
        type: 'custom',
        options: {
          Title: (value: string | boolean) => String(value),
        },
      },
      minWidth: 75,
      maxWidth: 100,
      Cell: ({ row: { original } }: ICellValue<TForceCloseRule>) => {
        return typeof original.value === 'boolean' || original.value
          ? String(original.value)
          : '';
      },
    },
    {
      Header: 'AutoExecute',
      accessor: 'autoExecute',
      disableSortBy: true,
      minWidth: 100,
      maxWidth: 125,
    },
    {
      Header: 'COT Level, %',
      accessor: 'closeOutTresholdLevel',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      minWidth: 100,
      maxWidth: 125,
      Cell: ({ row: { original } }: ICellValue<TForceCloseRule>) => {
        if (Number.isNaN(Number(original.closeOutTresholdLevel))) {
          return '';
        }

        return transferValueToPercent(original, 'closeOutTresholdLevel');
      },
    },
    {
      Header: 'Reset Level, %',
      accessor: 'resetLevel',
      disableSortBy: true,
      formatting: 'number',
      minWidth: 100,
      maxWidth: 125,
      Cell: ({ row: { original } }: ICellValue<TForceCloseRule>) => {
        if (Number.isNaN(Number(original.resetLevel))) {
          return '';
        }

        return transferValueToPercent(original, 'resetLevel');
      },
    },
    {
      Header: 'ID',
      accessor: 'id',
      minWidth: 75,
      maxWidth: 100,
    },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
      formatting: 'dateTimeUTC',
      minWidth: 175,
      maxWidth: 200,
      tooltip: {
        type: 'custom',
        options: {
          Title: (value: string) =>
            formatDate({
              date: value,
              format: DATE_TIME_WITH_SECONDS,
            }),
        },
      },
    },
    {
      Header: 'Inserted At',
      accessor: 'insertedAt',
      formatting: 'dateTimeUTC',
      minWidth: 175,
      maxWidth: 200,
      tooltip: {
        type: 'custom',
        options: {
          Title: (value: string) =>
            formatDate({
              date: value,
              format: DATE_TIME_WITH_SECONDS,
            }),
        },
      },
    },
  ];
};
