import { IconButton, styled } from 'react-ui-kit-exante';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '8px 24px 16px 24px',
}));

export const ClientContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
}));

export const CloseContainer = styled('div')(() => ({
  display: 'flex',
}));

export const ClientIDContainer = styled('div')(() => ({
  fontSize: '24px',
  lineHeight: '32px',
}));

export const ArrowRightIconContainer = styled(IconButton)(() => ({
  transform: 'rotate(180deg)',
}));
