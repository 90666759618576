import { MARK_OPTIONS } from './constants';
import type { TForceCloseRulePayload } from './types';

export const transformParams = ({
  mark,
  value,
  ...rest
}: TForceCloseRulePayload) => {
  if (mark === MARK_OPTIONS.QttyOfShortFOPoses) {
    return {
      mark,
      value: Number(value),
      ...rest,
    };
  }

  if (
    typeof value === 'string' &&
    (mark === MARK_OPTIONS.Assets || mark === MARK_OPTIONS.AssetsTypes)
  ) {
    return {
      mark,
      value: value
        .replace(/(, ")+/g, ',"') // ["ASD", "DSL", "VIC"] will be replaced to ["ASD","DSL","VIC"]
        .replace(/(, ')+/g, ",'") // ['ASD', 'DSL', 'VIC'] will be replaced to ['ASD','DSL','VIC']
        .replace(/["']/g, '') // ['ASD',"DSL","VIC"] will be replaced to [ASD,DSL,VIC]
        .replace(/[[\]']/g, '') // [ASD,DSL,VIC] will be replaced to ASD,DSL,VIC
        .split(','), // ASD,DSL,VIC will be splitted into array ['ASD','DSL','VIC']
      ...rest,
    };
  }

  return {
    mark,
    value,
    ...rest,
  };
};
