import { TParams } from 'types';
import { convertDateToUTC } from 'utils';

export function transformParams(params: TParams) {
  const paramsClone = { ...params };

  if (paramsClone.insertedAt) {
    const [from, to] = paramsClone.insertedAt as Date[];

    paramsClone.from = convertDateToUTC(from).toISOString().slice(0, 10);
    paramsClone.to = convertDateToUTC(to).toISOString().slice(0, 10);

    delete paramsClone.insertedAt;
  }

  return paramsClone;
}
