import { TMarkFunction } from 'types';

import { MarkFunctionRepository } from './markFunction.repository';

export class MarkFunctionService {
  public async fetchMarkFunctions(): Promise<TMarkFunction[]> {
    const { data } = await MarkFunctionRepository.fetchMarkFunctions();

    return data.marks;
  }
}
