import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { ForceCloseRuleActions } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { forceCloseRuleService } from 'resources';
import { TForceCloseRule } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { TFormForceCloseRule } from '../../types';
import { convertCOTLevel, convertResetLevel } from '../helpers';
import { VALIDATION_SCHEMA } from '../validationSchema';

export const useForceCloseRuleEditForm = () => {
  const { forceCloseRuleID } = useParams<{ forceCloseRuleID: string }>();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const data = locationState as TForceCloseRule;
  const [modalOpened, setModalOpened] = useState(false);
  const [overrideChecked, setOverrideChecked] = useState(
    data.action === ForceCloseRuleActions.Override,
  );

  const handleClose = useCallback(() => {
    navigate(ROUTES.RISK_EVENT);
  }, [navigate]);

  if (isEmpty(locationState)) {
    handleClose();
  }

  const methods = useForm({
    defaultValues: {
      ...data,
      closeOutTresholdLevel: convertCOTLevel(data),
      resetLevel: convertResetLevel(data),
    } as TFormForceCloseRule,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    setValue,
  } = methods;

  const onSubmit = async (payload: TFormForceCloseRule) => {
    try {
      await forceCloseRuleService.updateForceCloseRule(
        Number(forceCloseRuleID),
        {
          ...payload,
          closeOutTresholdLevel:
            payload.action === ForceCloseRuleActions.Override
              ? Number(payload.closeOutTresholdLevel) / 100
              : 100,
          resetLevel:
            payload.action === ForceCloseRuleActions.Override
              ? Number(payload.resetLevel) / 100
              : 100,
        },
      );

      Notification.success({
        title: 'Force Close Rule successfully updated',
      });

      handleClose();
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  };

  const handleDeleteClick = () => {
    setModalOpened(true);
  };

  const handleModalClose = () => {
    setModalOpened(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      await forceCloseRuleService.deleteForceCloseRule(
        Number(forceCloseRuleID),
      );

      Notification.success({
        title: `Force Close Rule successfully deleted with ID ${forceCloseRuleID}`,
      });

      handleClose();
    } catch (err) {
      sendUiKitErrorNotification(err);
    }

    setModalOpened(false);
  };

  const handleRuleActionChange = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (value: string) => void,
  ) => {
    const ruleAction = event.target.value;

    switch (ruleAction) {
      case ForceCloseRuleActions.Override: {
        setValue('closeOutTresholdLevel', convertCOTLevel(data));
        setValue('resetLevel', convertResetLevel(data));
        setOverrideChecked(true);
        onChange(ruleAction);
        break;
      }

      case ForceCloseRuleActions.Terminate: {
        setValue('closeOutTresholdLevel', convertCOTLevel(data));
        setValue('resetLevel', convertResetLevel(data));
        setOverrideChecked(false);
        onChange(ruleAction);
        break;
      }

      default: {
        break;
      }
    }
  };

  return {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleDeleteClick,
    handleDeleteConfirm,
    handleModalClose,
    handleRuleActionChange,
    handleSubmit,
    isDirty,
    modalOpened,
    onSubmit,
    overrideChecked,
  };
};
