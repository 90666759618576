import { createContext, Dispatch } from 'react';

import { initialState } from './initialState';
import { RiskEventActionsType, RiskEventState } from './types';

export const RiskEventContext = createContext<{
  state: RiskEventState;
  dispatch: Dispatch<RiskEventActionsType>;
}>({
  state: initialState,
  dispatch: () => null,
});
