import { boolean, object, string } from 'yup';

export const VALIDATION_SCHEMA = object().shape({
  autoExecute: boolean().nullable(),
  closeOutTresholdLevel: string().required(
    'CloseOutThresholdLevel is a required field',
  ),
  name: string().required('Name is a required field'),
  resetLevel: string().required('ResetLevel is a required field'),
});
