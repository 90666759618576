import { TMarkFunctionResponse } from 'types';
import { apiRequest } from 'utils';

import { MARK_FUNCTION_API } from './endpoints';

export class MarkFunctionRepository {
  public static fetchMarkFunctions() {
    return apiRequest<TMarkFunctionResponse>({
      url: MARK_FUNCTION_API,
    });
  }
}
