import { Global, css } from '@emotion/react';

import { fontFaces } from './fontFaces';

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        :root {
          /* Fonts */
          --font-main: Inter, sans-serif;
          --font-header: FoxSansPro, sans-serif;
          --font-sm: 12px;
          --font-md: 13px;
          --font-lg: 15px;

          /* Colors */
          --color-black: #252c2e;
          --color-light-blue: #eef6ff;
          --color-light-grey: #fafafa;
          --color-grey: #818c99;
          --color-green: #007f39;
          --color-light-green: #cbe3cd;
        }

        ${fontFaces}

        body {
          margin: 0;
          font-family: var(--font-main);
        }
      `}
    />
  );
};
