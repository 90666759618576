import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Notification } from 'react-ui-kit-exante';

import { forceCloseEventService } from 'resources';
import { TUpdateForceCloseEvent } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import {
  RiskEventActions,
  RiskEventActionsType,
  RiskEventState,
} from '../../../context';
import { INITIAL_VALUES } from '../constants';
import { VALIDATION_SCHEMA } from '../validationSchema';

type UseCommentFormProps = {
  dispatch: React.Dispatch<RiskEventActionsType>;
  refetch: () => Promise<void>;
  state: RiskEventState;
};

export const useCommentForm = ({
  dispatch,
  refetch,
  state,
}: UseCommentFormProps) => {
  const methods = useForm({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = methods;

  const onSubmit = async (payload: TUpdateForceCloseEvent) => {
    try {
      await forceCloseEventService.updateForceCloseEvent(
        state.commentModal.number,
        payload,
      );

      Notification.success({
        title: 'Comment successfully added',
      });

      dispatch({
        type: RiskEventActions.PostponeCommentModal,
        payload: {
          number: '',
          show: false,
        },
      });

      refetch();
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  };

  const handleModalClose = () => {
    dispatch({
      type: RiskEventActions.PostponeCommentModal,
      payload: {
        number: '',
        show: false,
      },
    });
  };

  return {
    control,
    errors,
    isValid,
    handleModalClose,
    handleSubmit,
    onSubmit,
  };
};
