import { AxiosResponse } from 'axios';

import type {
  TForceCloseEventState,
  TFormUpdateForceCloseEvent,
  TParams,
  TUpdateForceCloseEvent,
} from 'types';

import { ForceCloseEventRepository } from './forceCloseEvent.repository';
import { transformParams } from './helpers';

export class ForceCloseEventService {
  public async fetchForceCloseEvents(
    params: TParams,
  ): Promise<TForceCloseEventState> {
    const { data } = await ForceCloseEventRepository.fetchForceCloseEvents(
      transformParams(params),
    );

    return {
      count: data.count,
      forceCloseEvents: data.force_close_events,
    };
  }

  public async fetchForceEventByNumber(
    number: string,
  ): Promise<TForceCloseEventState> {
    try {
      const { data } =
        await ForceCloseEventRepository.fetchForceCloseEventByNumber(number);

      return {
        count: data.count,
        forceCloseEvents: data.force_close_events,
      };
    } catch (err) {
      return {} as TForceCloseEventState;
    }
  }

  public async updateForceCloseEvent(
    number: string,
    payload: TUpdateForceCloseEvent | TFormUpdateForceCloseEvent,
  ): Promise<AxiosResponse> {
    return ForceCloseEventRepository.updateForceCloseEvent(number, payload);
  }
}
