import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTableData } from 'react-ui-kit-exante';

import { forceCloseEventService, liquidationEstimateService } from 'resources';
import type { TLiquidationEstimate } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { RiskEventActions, RiskEventActionsType } from '../../context';
import { getColumns } from '../columns';
import { getDefaultSorting } from '../constants';

type UseLiquidationEstimateProps = {
  dispatch: React.Dispatch<RiskEventActionsType>;
  number: string;
  tableId: string;
};

export const useLiquidationEstimate = ({
  dispatch,
  number,
  tableId,
}: UseLiquidationEstimateProps) => {
  const getLiquidationEstimates = useCallback(async () => {
    dispatch({ type: RiskEventActions.FetchLiquidationEstimateStart });

    try {
      const forceCloseEventsData =
        await forceCloseEventService.fetchForceEventByNumber(number);
      const [fceData] = forceCloseEventsData.forceCloseEvents;

      const data = await liquidationEstimateService.createLiquidationEstimate({
        accounts: fceData.accountIds || [],
        actualResetLevel: fceData.actualResetLevel,
      });

      dispatch({
        type: RiskEventActions.FetchLiquidationEstimateSucceed,
      });

      return data;
    } catch (err) {
      sendUiKitErrorNotification(err);

      dispatch({
        payload: err as AxiosError,
        type: RiskEventActions.FetchLiquidationEstimateError,
      });

      return [];
    }
  }, [number]);

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getLiquidationEstimates },
      sorting: { getDefaultSorting },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getLiquidationEstimates, tableId],
  );

  const { data, isLoading, setSorting } =
    useTableData<TLiquidationEstimate[]>(tableDataArgs);

  const columns = useMemo(() => getColumns(), []);

  return {
    columns,
    data,
    isLoading,
    setSorting,
  };
};
