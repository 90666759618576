import { RiskEventState } from '../types';

export const onFetchPostponeStart = (
  state: RiskEventState,
): RiskEventState => ({
  ...state,

  fetchPostponeStatus: {
    error: null,
    pending: true,
    succeed: false,
  },
});
