export const DEFAULT_POLLING_INTERVAL_5 = 5000; // 5 seconds
export const DEFAULT_POLLING_INTERVAL_20 = 20000; // 20 seconds
export const DEFAULT_POLLING_INTERVAL_60 = 60000; // 1 min

export enum ForceCloseRuleActions {
  Override = 'Override',
  Terminate = 'Terminate',
}

export const ForceCloseRuleActionsOptions = Object.keys(
  ForceCloseRuleActions,
).map((action) => ({
  label: action,
  value: action,
}));

export const FORCE_CLOSE_EVENT_STATE = {
  active: 'a',
  close: 'c',
  passive: 'p',
};

export const ForceCloseEventStateOptions = Object.entries(
  FORCE_CLOSE_EVENT_STATE,
).map(([key, value]) => ({
  label: key,
  value,
}));

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong!';
