import { AxiosError } from 'axios';

import { RiskEventState } from '../types';

export const onFetchForceCloseRuleError = (
  state: RiskEventState,
  error: AxiosError,
): RiskEventState => {
  return {
    ...state,

    ready: true,

    fetchForceCloseRuleStatus: {
      error,
      pending: false,
      succeed: false,
    },
  };
};
