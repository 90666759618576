import type { TForceCloseRule, TSwapForceCloseRuleOrders } from 'types';
import { apiRequest } from 'utils';

import {
  FORCE_CLOSE_RULE_API,
  FORCE_CLOSE_RULE_ORDER_API,
  getForceCloseRuleByIdAPI,
} from './endpoints';
import { TForceCloseRulePayload } from './types';

export class ForceCloseRuleRepository {
  public static fetchForceCloseRules() {
    return apiRequest<TForceCloseRule[]>({
      url: FORCE_CLOSE_RULE_API,
    });
  }

  public static fetchForceCloseRuleById(id: number) {
    return apiRequest<TForceCloseRule>({
      url: getForceCloseRuleByIdAPI(id),
    });
  }

  public static createForceCloseRule(payload: TForceCloseRulePayload) {
    return apiRequest<TForceCloseRule>({
      url: FORCE_CLOSE_RULE_API,
      method: 'POST',
      data: payload,
    });
  }

  public static updateForceCloseRule(
    id: number,
    payload: TForceCloseRulePayload,
  ) {
    return apiRequest<TForceCloseRule>({
      url: getForceCloseRuleByIdAPI(id),
      method: 'PUT',
      data: payload,
    });
  }

  public static deleteForceCloseRule(id: number) {
    return apiRequest({
      url: getForceCloseRuleByIdAPI(id),
      method: 'DELETE',
    });
  }

  public static swapForceCloseRuleOrder(payload: TSwapForceCloseRuleOrders) {
    return apiRequest({
      url: FORCE_CLOSE_RULE_ORDER_API,
      method: 'POST',
      data: payload,
    });
  }
}
