import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IRow,
  Notification,
  TSwapRows,
  useTableData,
} from 'react-ui-kit-exante';

import { RiskEventRoutes } from 'pages/RiskEvent/routes';
import { forceCloseRuleService } from 'resources';
import { TForceCloseRule, TSwapForceCloseRuleOrders } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { RiskEventActions, RiskEventActionsType } from '../../context';
import { getColumns } from '../columns';
import { prepareSwapRowsPayload } from '../helpers';
import { TCellProps } from '../types';

type UseForceCloseRulesProps = {
  dispatch: React.Dispatch<RiskEventActionsType>;
  markOptions: Record<string, string>;
  tableId: string;
};

export const useForceCloseRules = ({
  dispatch,
  markOptions,
  tableId,
}: UseForceCloseRulesProps) => {
  const navigate = useNavigate();

  const getForceCloseRules = useCallback(async () => {
    dispatch({ type: RiskEventActions.FetchForceCloseRuleStart });

    try {
      const data = await forceCloseRuleService.fetchForceCloseRules();

      dispatch({
        type: RiskEventActions.FetchForceCloseRuleSucceed,
      });

      return data;
    } catch (err) {
      sendUiKitErrorNotification(err);

      dispatch({
        payload: err as AxiosError,
        type: RiskEventActions.FetchForceCloseRuleError,
      });

      return [];
    }
  }, []);

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getForceCloseRules },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getForceCloseRules, tableId],
  );

  const {
    data,
    fetchData: refetch,
    isLoading,
  } = useTableData<TForceCloseRule[]>(tableDataArgs);

  const columns = useMemo(() => getColumns({ markOptions }), [markOptions]);

  const handleForceCloseRuleAdd = useCallback(() => {
    navigate(RiskEventRoutes.FORCE_CLOSE_RULE_ADD);
  }, [navigate]);

  const handleCellClick = useCallback(
    ({ column: { id }, row }: TCellProps) => {
      if (id === 'swapRows' || id === 'autoLiquidate') {
        return;
      }

      const { original } = row;

      if (original.id === 0) {
        navigate(RiskEventRoutes.FORCE_CLOSE_RULE_EDIT_BASE_RULE, {
          state: {
            ...original,
          },
        });

        return;
      }

      if (original.id) {
        navigate(`${RiskEventRoutes.FORCE_CLOSE_RULE}/${original.id}`, {
          state: {
            ...original,
          },
        });
      }
    },
    [navigate],
  );

  const handleSwap = useCallback(
    async (
      direction: string,
      row: IRow<TForceCloseRule>,
      rows: IRow<TForceCloseRule>[],
    ) => {
      try {
        dispatch({
          type: RiskEventActions.FetchForceCloseRuleStart,
        });

        const payload: TSwapForceCloseRuleOrders | null =
          prepareSwapRowsPayload(direction, row, rows);

        if (!payload) {
          Notification.warning({
            title: 'Base Rule order can not be changed',
          });
        }

        if (payload) {
          await forceCloseRuleService.swapForceCloseRuleOrder(payload);

          Notification.success({
            title: 'Force Close Rules successfully changed orders',
          });
        }

        refetch();
      } catch (err) {
        sendUiKitErrorNotification(err);
      }
    },
    [refetch],
  );

  const handleSwapDown = useCallback(
    (
      direction: string,
      row: IRow<TForceCloseRule>,
      rows: IRow<TForceCloseRule>[],
    ) => {
      handleSwap(direction, row, rows);
    },
    [handleSwap],
  );

  const handleSwapUp = useCallback(
    (
      direction: string,
      row: IRow<TForceCloseRule>,
      rows: IRow<TForceCloseRule>[],
    ) => {
      handleSwap(direction, row, rows);
    },
    [handleSwap],
  );

  const swapRows: TSwapRows<TForceCloseRule> = useMemo(() => {
    return {
      headerOptions: {
        Header: 'Priority',
      },
      swapRowDown: handleSwapDown,
      swapRowUp: handleSwapUp,
      show: true,
    };
  }, [handleSwapDown, handleSwapUp]);

  return {
    columns,
    data,
    handleCellClick,
    handleForceCloseRuleAdd,
    isLoading,
    refetch,
    swapRows,
  };
};
