import { styled, Input } from 'react-ui-kit-exante';

export const ControlsContainer = styled('div')(({ theme }) => ({
  background: theme.color.bg.primary,
  borderTop: `1px ${theme.color.dropdown.border} solid`,
  minWidth: '400px',
}));

export const ControlsInputMultiline = styled(Input)(() => ({
  marginTop: '16px !important',
  width: '100%',

  '& .MuiInputBase-multiline': {
    paddingTop: '20px',
  },
  '& textarea': {
    padding: '0 !important',
  },
}));
