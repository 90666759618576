import { RiskEventActions } from './actions';
import {
  onFetchForceCloseEventError,
  onFetchForceCloseEventStart,
  onFetchForceCloseEventSucceed,
  onFetchForceCloseEventsListError,
  onFetchForceCloseEventsListStart,
  onFetchForceCloseEventsListSucceed,
  onFetchForceCloseRuleError,
  onFetchForceCloseRuleStart,
  onFetchForceCloseRuleSucceed,
  onFetchLiquidationEstimateError,
  onFetchLiquidationEstimateStart,
  onFetchLiquidationEstimateSucceed,
  onFetchMarkFunctionSucceed,
  onFetchPostponeError,
  onFetchPostponeStart,
  onFetchPostponeSucceed,
  onForceCloseEventTogglePostponeModal,
  onPostponeToggleCommentModal,
  onPostponeToggleResolutionModal,
  onResetForceCloseEventNumber,
  onSetForceCloseEventNumber,
} from './handlers';
import type { RiskEventReducer, RiskEventState } from './types';

export const reducer: RiskEventReducer = (state, action): RiskEventState => {
  switch (action.type) {
    /**
     * Force Close Event
     */
    case RiskEventActions.FetchForceCloseEventError:
      return onFetchForceCloseEventError(state, action.payload);

    case RiskEventActions.FetchForceCloseEventStart:
      return onFetchForceCloseEventStart(state);

    case RiskEventActions.FetchForceCloseEventSucceed:
      return onFetchForceCloseEventSucceed(state);

    case RiskEventActions.SetForceCloseEventNumber:
      return onSetForceCloseEventNumber(state, action.payload);

    case RiskEventActions.ResetForceCloseEventNumber:
      return onResetForceCloseEventNumber(state);

    /**
     * Force Close Events List
     */
    case RiskEventActions.FetchForceCloseEventsListError:
      return onFetchForceCloseEventsListError(state, action.payload);

    case RiskEventActions.FetchForceCloseEventsListStart:
      return onFetchForceCloseEventsListStart(state);

    case RiskEventActions.FetchForceCloseEventsListSucceed:
      return onFetchForceCloseEventsListSucceed(state);

    /**
     * Force Close Event Postpone Modal
     */
    case RiskEventActions.ForceCloseEventPostponeModal:
      return onForceCloseEventTogglePostponeModal(state, action.payload);

    /**
     * Force Close Rule
     */
    case RiskEventActions.FetchForceCloseRuleError:
      return onFetchForceCloseRuleError(state, action.payload);

    case RiskEventActions.FetchForceCloseRuleStart:
      return onFetchForceCloseRuleStart(state);

    case RiskEventActions.FetchForceCloseRuleSucceed:
      return onFetchForceCloseRuleSucceed(state);

    /**
     * Mark Function
     */
    case RiskEventActions.FetchMarkFunctionSucceed:
      return onFetchMarkFunctionSucceed(state, action.payload);

    /**
     * Postpone
     */
    case RiskEventActions.FetchPostponeError:
      return onFetchPostponeError(state, action.payload);

    case RiskEventActions.FetchPostponeStart:
      return onFetchPostponeStart(state);

    case RiskEventActions.FetchPostponeSucceed:
      return onFetchPostponeSucceed(state);

    /**
     * Liquidation Estimate
     */
    case RiskEventActions.FetchLiquidationEstimateError:
      return onFetchLiquidationEstimateError(state, action.payload);

    case RiskEventActions.FetchLiquidationEstimateStart:
      return onFetchLiquidationEstimateStart(state);

    case RiskEventActions.FetchLiquidationEstimateSucceed:
      return onFetchLiquidationEstimateSucceed(state);

    /**
     * Postpone Resolution
     */
    case RiskEventActions.PostponeResolutionModal:
      return onPostponeToggleResolutionModal(state, action.payload);

    /**
     * Postpone Comment
     */
    case RiskEventActions.PostponeCommentModal:
      return onPostponeToggleCommentModal(state, action.payload);

    default:
      return state;
  }
};
