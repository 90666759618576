import { useContext } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { RiskEventContext } from 'pages/RiskEvent/context';

import { useLiquidationEstimateHead } from './hooks';
import {
  ArrowRightIconContainer,
  ClientContainer,
  ClientIDContainer,
  CloseContainer,
  Container,
} from './styled';
import type { TLiquidationEstimateHeadProps } from './types';

export const LiquidationEstimateHead = ({
  forceCloseEventsList,
  number,
}: TLiquidationEstimateHeadProps) => {
  const { dispatch } = useContext(RiskEventContext);

  const { currentFCE, handleClose, handleMoveToNext, handleMoveToPrevious } =
    useLiquidationEstimateHead({
      dispatch,
      forceCloseEventsList,
      number,
    });

  return (
    <Container>
      <ClientContainer>
        <IconButton
          disabled={currentFCE.index === 0}
          iconColor="secondary"
          iconName="ArrowLeftIcon"
          iconSize={20}
          onClick={handleMoveToPrevious}
        />
        <ClientIDContainer>{currentFCE.clientID}</ClientIDContainer>
        <ArrowRightIconContainer
          disabled={currentFCE.index === forceCloseEventsList.length - 1}
          iconColor="secondary"
          iconName="ArrowLeftIcon"
          iconSize={20}
          onClick={handleMoveToNext}
        />
      </ClientContainer>
      <CloseContainer>
        <IconButton
          iconColor="secondary"
          iconName="CloseIcon"
          iconSize={24}
          onClick={handleClose}
        />
      </CloseContainer>
    </Container>
  );
};
