import { RiskEventState } from '../types';

export const onFetchLiquidationEstimateSucceed = (
  state: RiskEventState,
): RiskEventState => ({
  ...state,

  ready: true,

  fetchLiquidationEstimateStatus: {
    error: null,
    pending: false,
    succeed: true,
  },
});
