import { RiskEventState } from '../types';

export const onSetForceCloseEventNumber = (
  state: RiskEventState,
  payload: string,
): RiskEventState => ({
  ...state,

  forceCloseEventNumber: payload,
});
