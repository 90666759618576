import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { ForceCloseRuleActions } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { forceCloseRuleService } from 'resources';
import { sendUiKitErrorNotification } from 'utils';

import { TFormForceCloseRule } from '../../types';
import { DEFAULT_VALUES, PERCENT } from '../constants';
import { VALIDATION_SCHEMA } from '../validationSchema';

export const useForceCloseRuleAddForm = () => {
  const navigate = useNavigate();
  const [overrideChecked, setOverrideChecked] = useState(true);

  const handleClose = useCallback(() => {
    navigate(ROUTES.RISK_EVENT);
  }, [navigate]);

  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    setValue,
  } = methods;

  const onSubmit = async (payload: TFormForceCloseRule) => {
    try {
      await forceCloseRuleService.createForceCloseRule({
        ...payload,
        closeOutTresholdLevel: Number(payload.closeOutTresholdLevel) / PERCENT,
        resetLevel: Number(payload.resetLevel) / PERCENT,
      });

      Notification.success({
        title: 'Force Close Rule successfully created',
      });

      handleClose();
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  };

  const handleRuleActionChange = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (value: string) => void,
  ) => {
    const ruleAction = event.target.value;

    switch (ruleAction) {
      case ForceCloseRuleActions.Terminate: {
        setOverrideChecked(false);
        setValue('closeOutTresholdLevel', '100.00');
        setValue('resetLevel', '100.00');
        onChange(ruleAction);
        break;
      }

      default: {
        setOverrideChecked(true);
        setValue('closeOutTresholdLevel', '110.00');
        setValue('resetLevel', '100.00');
        onChange(ruleAction);
        break;
      }
    }
  };

  return {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleRuleActionChange,
    handleSubmit,
    isDirty,
    onSubmit,
    overrideChecked,
  };
};
