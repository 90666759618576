import { TPostponeModal } from 'pages/RiskEvent/types';

import { RiskEventState } from '../types';

export const onForceCloseEventTogglePostponeModal = (
  state: RiskEventState,
  payload: TPostponeModal,
): RiskEventState => ({
  ...state,

  postponeModal: payload,
});
