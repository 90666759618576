const RESET_PAGE = 0;

export const resetTablePage = (tableId: string) => {
  try {
    const tableViewParams =
      localStorage.getItem(`${tableId}-view-params`) || '';

    const keys = JSON.parse(tableViewParams);
    keys.page = RESET_PAGE;

    localStorage.setItem(`${tableId}-view-params`, JSON.stringify(keys));
  } catch (err) {
    console.error(
      `Local Storage Parse / Stringify Error on TableID: ${tableId}-view-params`,
    );
  }
};
