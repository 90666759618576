import { Link, useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import { BrandContainer, Container, LinkStyled } from './styled';

export const Header = () => {
  const { pathname } = useLocation();
  const isInsideRun = !!window.runUIhistoryContainer;
  const pageName = pathname.split('/')[2];

  return !isInsideRun ? (
    <Container>
      <BrandContainer>
        <Link to={ROUTES.Main}>Remus UI</Link>
        <div>{`version ${process?.env?.REACT_APP_VERSION}`}</div>
      </BrandContainer>
      <div>
        <LinkStyled
          to={ROUTES.RISK_EVENT}
          pageName={pageName === ROUTES.RISK_EVENT.split('/')[2]}
        >
          Risk Event
        </LinkStyled>
      </div>
    </Container>
  ) : null;
};
