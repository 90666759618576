import { styled, Table } from 'react-ui-kit-exante';

export const TableContainer = styled(Table)(() => ({
  '& div[data-rbd-draggable-id="swapRows"]': {
    flex: '80 0 auto !important',
    minWidth: '80px !important',
    width: '80px !important',
  },
  '& div[data-test-id="table__body--cell"]:first-of-type': {
    flex: '80 0 auto !important',
    minWidth: '80px !important',
    width: '80px !important',
  },
}));
