import { ForceCloseRuleActions } from 'constants/common';
import { TForceCloseRule } from 'types';

import { PERCENT } from './constants';

export const convertCOTLevel = (data: TForceCloseRule) => {
  return data.action === ForceCloseRuleActions.Override
    ? String(((data?.closeOutTresholdLevel || 1.1) * PERCENT).toFixed(2))
    : '110.00';
};

export const convertResetLevel = (data: TForceCloseRule) => {
  return data.action === ForceCloseRuleActions.Override
    ? String(((data?.resetLevel || 1) * PERCENT).toFixed(2))
    : '100.00';
};
