export const enum RiskEventActions {
  FetchForceCloseRuleError = 'Force Close Rule: Fetch Error',
  FetchForceCloseRuleStart = 'Force Close Rule: Fetch Start',
  FetchForceCloseRuleSucceed = 'Force Close Rule: Fetch Succeed',

  FetchForceCloseEventError = 'Force Close Event: Fetch Error',
  FetchForceCloseEventStart = 'Force Close Event: Fetch Start',
  FetchForceCloseEventSucceed = 'Force Close Event: Fetch Succeed',

  FetchForceCloseEventsListError = 'Force Close Events List: Fetch Error',
  FetchForceCloseEventsListStart = 'Force Close Events List: Fetch Start',
  FetchForceCloseEventsListSucceed = 'Force Close Events List: Fetch Succeed',

  FetchPostponeError = 'Postpone: Fetch Error',
  FetchPostponeStart = 'Postpone: Fetch Start',
  FetchPostponeSucceed = 'Postpone: Fetch Succeed',

  FetchLiquidationEstimateError = 'Liquidation Estimate: Fetch Error',
  FetchLiquidationEstimateStart = 'Liquidation Estimate: Fetch Start',
  FetchLiquidationEstimateSucceed = 'Liquidation Estimate: Fetch Succeed',

  SetForceCloseEventNumber = 'Force Close Event: Set Number',
  ResetForceCloseEventNumber = 'Force Close Event: Reset Number',

  ForceCloseEventPostponeModal = 'Force Close Event: Postpone Modal',

  PostponeResolutionModal = 'Postpone: Resolution Modal',
  PostponeCommentModal = 'Postpone: Comment Modal',

  FetchMarkFunctionSucceed = 'Mark Function: Fetch Succeed',
}
