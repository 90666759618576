import { ISortBy } from 'react-ui-kit-exante';

export const DISPLAYED_COLUMN_KEYS = [
  'accountId',
  'accountQtty',
  'asset',
  'assetCummulativeDelta',
  'delta',
  'index',
  'reason',
  'quantity',
];

export function getDefaultSorting(): ISortBy[] {
  return [{ id: 'index', desc: false }];
}
export const DEFAULT_SORTING = getDefaultSorting();
