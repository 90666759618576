import { MutableRefObject, useContext, useEffect } from 'react';

import { TForceCloseEvent } from 'types';
import { prepareTableId } from 'utils';

import { RiskEventContext } from '../context';

import { LiquidationEstimateHead } from './LiquidationEstimateHead';
import { DEFAULT_SORTING, DISPLAYED_COLUMN_KEYS } from './constants';
import { useLiquidationEstimate } from './hooks';
import { TableContainer } from './styled';

type TForceCloseEventProps = {
  liquidationEstimateRef: MutableRefObject<HTMLDivElement | null>;
  forceCloseEventsList: TForceCloseEvent[];
  number: string;
};

export const LiquidationEstimate = ({
  liquidationEstimateRef,
  forceCloseEventsList,
  number,
}: TForceCloseEventProps) => {
  const { state, dispatch } = useContext(RiskEventContext);

  const tableId = prepareTableId('forceCloseEventTable');

  const { columns, data, isLoading, setSorting } = useLiquidationEstimate({
    dispatch,
    number,
    tableId,
  });

  useEffect(() => {
    liquidationEstimateRef?.current?.scrollIntoView({
      behavior: 'auto',
    });
  }, []);

  return (
    <div ref={liquidationEstimateRef}>
      <TableContainer
        columns={columns}
        customHeadComponent={
          <LiquidationEstimateHead
            forceCloseEventsList={forceCloseEventsList}
            number={number}
          />
        }
        data={data || []}
        defaultSortBy={DEFAULT_SORTING}
        displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
        isFlexLayout
        isLoading={isLoading || state.fetchLiquidationEstimateStatus.pending}
        manualSortBy
        onSort={setSorting}
        saveColumnOrder
        saveViewParamsAfterLeave
        showScrollbar
        skeletonsCount={5}
        tableId={tableId}
      />
    </div>
  );
};
