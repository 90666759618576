import { ForceCloseRuleActions } from 'constants/common';

import { TFormForceCloseRule } from '../types';

export const CONTROL_CLS = { mt: '16px', width: '290px' };
export const PERCENT = 100;

export const DEFAULT_VALUES: TFormForceCloseRule = {
  action: ForceCloseRuleActions.Override,
  closeOutTresholdLevel: '110.00',
  func: '',
  mark: '',
  mark_context: '',
  name: '',
  resetLevel: '100.00',
  value: '',
};
