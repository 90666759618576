import type {
  TLiquidationEstimatePayload,
  TLiquidationEstimateResponse,
} from 'types';
import { apiRequest } from 'utils';

import { LIQUIDATION_ESTIMATE_API } from './endpoints';

export class LiquidationEstimateRepository {
  public static createLiquidationEstimate(
    payload: TLiquidationEstimatePayload,
  ) {
    return apiRequest<TLiquidationEstimateResponse>({
      url: LIQUIDATION_ESTIMATE_API,
      method: 'POST',
      data: payload,
    });
  }
}
