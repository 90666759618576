import type { TLiquidationEstimate, TLiquidationEstimateResponse } from 'types';

export const transformData = (
  data: TLiquidationEstimateResponse,
): TLiquidationEstimate[] => {
  const estimatesLength = data.estimates.length || 0;
  const excludedAssetsLength = data.excludedAssets.length || 0;

  const result = [] as TLiquidationEstimate[];

  for (let i = 0; i < estimatesLength; i += 1) {
    result.push({
      accountId: data.estimates[i].accountId,
      accountQtty: Number(data.estimates[i].accountQtty),
      asset: data.estimates[i].asset,
      assetCummulativeDelta: Number(data.estimates[i].assetCummulativeDelta),
      delta: Number(data.estimates[i].delta),
      index: Number(data.estimates[i].index),
      reason: '',
      quantity: Number(data.estimates[i].quantity),
    });
  }

  for (let i = 0; i < excludedAssetsLength; i += 1) {
    result.push({
      accountId: '',
      accountQtty: 0,
      asset: data.excludedAssets[i].asset,
      assetCummulativeDelta: 0,
      delta: 0,
      index: 0,
      reason: data.excludedAssets[i].reason,
      quantity: 0,
    });
  }

  return result;
};
