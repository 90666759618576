import type { TLiquidationEstimate, TLiquidationEstimatePayload } from 'types';

import { transformData } from './helpers';
import { LiquidationEstimateRepository } from './liquidationEstimate.repository';

export class LiquidationEstimateService {
  public async createLiquidationEstimate(
    payload: TLiquidationEstimatePayload,
  ): Promise<TLiquidationEstimate[]> {
    try {
      const { data } =
        await LiquidationEstimateRepository.createLiquidationEstimate(payload);

      return transformData(data);
    } catch (err) {
      return [] as TLiquidationEstimate[];
    }
  }
}
