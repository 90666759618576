import { SyntheticEvent, useMemo, useReducer, useState } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { Tab, TabPanel } from 'react-ui-kit-exante';

import { ForceCloseEventsList } from './ForceCloseEventsList';
import { ForceCloseRuleAdd } from './ForceCloseRuleAdd';
import { ForceCloseRuleEdit } from './ForceCloseRuleEdit';
import { ForceCloseRuleEditBaseRule } from './ForceCloseRuleEditBaseRule';
import { ForceCloseRules } from './ForceCloseRules';
import { Postpones } from './Postpones';
import { ACTIVE_TAB, Tabs } from './constants';
import {
  RiskEventContext,
  RiskEventReducer,
  initialState,
  reducer,
} from './context';
import { RiskEventRoutes } from './routes';
import {
  TabsContainer,
  TabsHeader,
  TabsHeaderTitle,
  TabsWrapper,
} from './styled';

export const RiskEvent = () => {
  const [state, dispatch] = useReducer<RiskEventReducer>(reducer, initialState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const [selectedTab, setSelectedTab] = useState(ACTIVE_TAB);
  const [, setSearchParams] = useSearchParams();

  const handleChange = (_: SyntheticEvent, newValue: unknown) => {
    /**
     * We need to clear the query params
     * otherwise the same query params are used in the selected tab
     */
    setSearchParams({});
    setSelectedTab(newValue as number);
  };

  return (
    <RiskEventContext.Provider value={contextValue}>
      <Routes>
        <Route
          path=""
          element={
            <TabsWrapper>
              <TabsHeader>
                <TabsHeaderTitle>Risk Events</TabsHeaderTitle>
              </TabsHeader>
              <TabsContainer value={selectedTab} onChange={handleChange}>
                <Tab label="Force Close Rules" />
                <Tab label="Force Close Events" />
                <Tab label="Postpones" />
              </TabsContainer>
              <TabPanel value={selectedTab} index={Tabs.ForceCloseRules}>
                <ForceCloseRules />
              </TabPanel>
              <TabPanel value={selectedTab} index={Tabs.ForceCloseEvents}>
                <ForceCloseEventsList />
              </TabPanel>
              <TabPanel value={selectedTab} index={Tabs.Postpones}>
                <Postpones />
              </TabPanel>
            </TabsWrapper>
          }
        />
        <Route
          path={RiskEventRoutes.FORCE_CLOSE_RULE_ADD}
          element={<ForceCloseRuleAdd />}
        />
        <Route
          path={RiskEventRoutes.FORCE_CLOSE_RULE_EDIT_BASE_RULE}
          element={<ForceCloseRuleEditBaseRule />}
        />
        <Route
          path={RiskEventRoutes.FORCE_CLOSE_RULE_EDIT}
          element={<ForceCloseRuleEdit />}
        />
      </Routes>
    </RiskEventContext.Provider>
  );
};
