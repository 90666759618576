import { useContext, useEffect } from 'react';

import { markFunctionService } from 'resources';
import { sendUiKitErrorNotification } from 'utils';

import { RiskEventActions, RiskEventContext } from '../context';

export const useFetchMarkFunction = () => {
  const { state, dispatch } = useContext(RiskEventContext);

  const fetchMarkFunctions = async () => {
    try {
      const data = await markFunctionService.fetchMarkFunctions();

      dispatch({
        type: RiskEventActions.FetchMarkFunctionSucceed,
        payload: data.length ? data : [],
      });

      return;
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  };

  useEffect(() => {
    if (state.markFunctions.length > 0) {
      return;
    }

    fetchMarkFunctions();
  }, []);
};
