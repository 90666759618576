import type { TParams, TPostponeResponse } from 'types';
import { apiRequest } from 'utils';

import { POSTPONE_API } from './endpoints';

export class PostponeRepository {
  public static fetchPostpones(params: TParams) {
    return apiRequest<TPostponeResponse>({
      params,
      url: POSTPONE_API,
    });
  }
}
