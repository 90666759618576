import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ICellValue,
  Theme,
  calculateCountOfPages,
  useTableData,
} from 'react-ui-kit-exante';

import { forceCloseEventService } from 'resources';
import { TForceCloseEvent, TForceCloseEventState, TParams } from 'types';
import { resetTablePage, sendUiKitErrorNotification } from 'utils';

import { RiskEventActions, RiskEventActionsType } from '../../context';
import { getColumns } from '../columns';
import { getDefaultPagination, getDefaultSorting } from '../constants';
import { getAdditionalFilters } from '../filters';
import { TCellProps } from '../types';

import { useBulkActions } from './useBulkActions';

type UseForceCloseEventsProps = {
  dispatch: React.Dispatch<RiskEventActionsType>;
  tableId: string;
};

type TGetForceCloseEvents = {
  params: TParams;
};

export const useForceCloseEventsList = ({
  dispatch,
  tableId,
}: UseForceCloseEventsProps) => {
  const navigate = useNavigate();

  const getForceCloseEvents = useCallback(
    async ({ params: { page, skip, ...rest } }: TGetForceCloseEvents) => {
      const updatedParams: TParams = {
        ...rest,
        offset: skip,
        postponed: false,
      };

      dispatch({ type: RiskEventActions.FetchForceCloseEventsListStart });

      try {
        const data = await forceCloseEventService.fetchForceCloseEvents(
          updatedParams,
        );

        dispatch({
          type: RiskEventActions.FetchForceCloseEventsListSucceed,
        });

        return data;
      } catch (err) {
        sendUiKitErrorNotification(err);

        dispatch({
          payload: err as AxiosError,
          type: RiskEventActions.FetchForceCloseEventsListError,
        });

        return {
          count: 0,
          forceCloseEvents: [],
        };
      }
    },
    [],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getForceCloseEvents },
      pagination: {
        getDefaultPagination,
      },
      sorting: { getDefaultSorting },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getForceCloseEvents, tableId],
  );

  const {
    data,
    isLoading,
    fetchData: refetch,
    filters,
    limit,
    page,
    removeFilter,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
    setSorting,
  } = useTableData<TForceCloseEventState>(tableDataArgs);

  const {
    handleAutoLiquidate,
    handleAutoLiquidateConfirm,
    handleModalClose,
    handleSelectRow,
    hasRows,
    modalOpened,
    selectedRows,
    setSelectedRows,
  } = useBulkActions({ refetch });

  const handlePostpone = useCallback((rowValue: unknown) => {
    const { number } = rowValue as TForceCloseEvent;

    dispatch({
      type: RiskEventActions.ForceCloseEventPostponeModal,
      payload: {
        number,
        show: true,
      },
    });
  }, []);

  const handleCellClick = useCallback(
    ({ column: { id }, row }: TCellProps) => {
      if (id !== 'clientId') {
        return;
      }

      const { original } = row;

      if (original.number) {
        dispatch({
          type: RiskEventActions.SetForceCloseEventNumber,
          payload: original.number,
        });
      }
    },
    [navigate],
  );

  const getCellProps = (
    { column }: ICellValue<TForceCloseEvent>,
    theme: Theme,
  ) => {
    if (column.id === 'clientId') {
      return {
        style: {
          color: theme.color.typo.action,
          cursor: 'pointer',
          fontWeight: 700,
        },
      };
    }

    return {};
  };

  const columns = useMemo(
    () =>
      getColumns({
        handlePostpone,
        onFilter: setFilter,
        onRemove: removeFilter,
        tableId,
      }),
    [handlePostpone, removeFilter, selectedRows, setFilter, tableId],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        filters,
        onFilter: setFilter,
        onRemove: removeFilter,
        tableId,
      }),
    [filters, removeFilter, setFilter, tableId],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [filters, resetFilters],
  );

  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPageSize: (pageSize: number) => {
        resetTablePage(tableId);
        setLimit(pageSize);
      },
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  return {
    columns,
    data,
    filteringProps,
    getCellProps,
    handleAutoLiquidate,
    handleAutoLiquidateConfirm,
    handleCellClick,
    handleModalClose,
    handleSelectRow,
    hasRows,
    isLoading,
    modalOpened,
    refetch,
    selectedRows,
    serverPaginationProps,
    setSelectedRows,
    setSorting,
  };
};
