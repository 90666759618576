import type { TMarkFunction } from 'types';

import type { RiskEventState } from '../types';

export const onFetchMarkFunctionSucceed = (
  state: RiskEventState,
  payload: TMarkFunction[],
): RiskEventState => ({
  ...state,

  markFunctions: payload,
});
