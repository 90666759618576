import { useContext, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import {
  Checkbox,
  ISelectOption,
  IconButton,
  Input,
  Modal,
  RadioGroup,
  Select,
  Tooltip,
} from 'react-ui-kit-exante';

import { ForceCloseRuleActions } from 'constants/common';
import { COMPARE_FUNCTION } from 'constants/compareFunction';

import { RiskEventContext } from '../context';
import { useFetchMarkFunction } from '../hooks';

import { CONTROL_CLS } from './constants';
import { useForceCloseRuleEditForm } from './hooks';
import {
  ActionsContainer,
  ControlsContainer,
  ControlsInputMultiline,
  ControlsRadioGroup,
  ControlsRow,
  PanelContainer,
} from './styled';

export const ForceCloseRuleEdit = () => {
  const { state } = useContext(RiskEventContext);

  useFetchMarkFunction();

  const markOptions = useMemo<ISelectOption[]>(
    () =>
      state.markFunctions.map((mark) => ({
        label: (
          <Tooltip title={mark.description}>
            <div>{mark.name}</div>
          </Tooltip>
        ),
        value: mark.code,
      })),
    [state.markFunctions],
  );

  const { current: functionOptions } = useRef<ISelectOption[]>(
    COMPARE_FUNCTION.map((func) => ({
      label: func,
      value: func,
    })),
  );

  const { current: actionOptions } = useRef(
    Object.keys(ForceCloseRuleActions).map((action) => ({
      label: action,
      value: action,
    })),
  );

  const {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleDeleteClick,
    handleDeleteConfirm,
    handleModalClose,
    handleRuleActionChange,
    handleSubmit,
    isDirty,
    modalOpened,
    onSubmit,
    overrideChecked,
  } = useForceCloseRuleEditForm();

  const renderActions = () => (
    <ActionsContainer>
      <IconButton
        disabled={!isDirty}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={24}
        label="Save"
        type="submit"
      />
      <IconButton
        iconColor="radical"
        iconName="DeleteIcon"
        iconSize={24}
        label="Delete"
        type="button"
        onClick={handleDeleteClick}
      />
      <IconButton
        iconColor="secondary"
        iconName="CloseIcon"
        iconSize={28}
        onClick={handleClose}
      />
    </ActionsContainer>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PanelContainer title="Edit Rule" action={renderActions()} />

      <Modal
        isOpened={modalOpened}
        onClose={handleModalClose}
        title="Delete Rule"
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleDeleteConfirm,
        }}
      >
        <div>Are you sure you want to delete the rule?</div>
      </Modal>

      <ControlsContainer>
        <Controller
          name="id"
          control={control}
          render={({ field }) => (
            <Input {...field} disabled label="Rule ID" sx={CONTROL_CLS} />
          )}
        />
        <Controller
          name="order"
          control={control}
          render={({ field }) => (
            <Input {...field} disabled label="Rule Order" sx={CONTROL_CLS} />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Rule Name"
              sx={{
                ...CONTROL_CLS,
                width: '100%',
              }}
              value={field.value}
              warning={dirtyFields.name}
              error={Boolean(errors.name)}
              message={errors.name?.message}
            />
          )}
        />
        <Controller
          name="mark"
          control={control}
          render={({ field }) => (
            <Select
              label="Mark Function"
              onChange={field.onChange}
              options={markOptions}
              sx={CONTROL_CLS}
              value={field.value}
            />
          )}
        />
        <Controller
          name="mark_context"
          control={control}
          render={({ field }) => (
            <ControlsInputMultiline
              {...field}
              label="Context"
              multiline
              rows={4}
              warning={dirtyFields.mark_context}
              error={Boolean(errors.mark_context)}
              message={errors.mark_context?.message}
            />
          )}
        />
        <Controller
          name="func"
          control={control}
          render={({ field }) => (
            <Select
              label="Compare Function"
              onChange={field.onChange}
              options={functionOptions}
              sx={CONTROL_CLS}
              value={field.value}
            />
          )}
        />
        <Controller
          name="value"
          control={control}
          render={({ field }) => (
            <ControlsInputMultiline
              {...field}
              label="Value"
              multiline
              rows={4}
              value={String(field.value)}
              warning={dirtyFields.value}
              error={Boolean(errors.value)}
              message={errors.value?.message}
            />
          )}
        />
        <Controller
          name="autoExecute"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              label="AutoExecute"
              sx={CONTROL_CLS}
            />
          )}
        />
        <ControlsRadioGroup>
          <Controller
            name="action"
            control={control}
            render={({ field }) => (
              <RadioGroup
                formLabel="Rule Action"
                handleChange={(event) =>
                  handleRuleActionChange(event, field.onChange)
                }
                radioOptions={actionOptions}
                value={field.value}
              />
            )}
          />
        </ControlsRadioGroup>
        {overrideChecked && (
          <ControlsRow>
            <Controller
              name="closeOutTresholdLevel"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  sx={CONTROL_CLS}
                  label="COT Level, %"
                  type="text"
                  warning={dirtyFields.closeOutTresholdLevel}
                  error={Boolean(errors.closeOutTresholdLevel)}
                  message={errors.closeOutTresholdLevel?.message}
                />
              )}
            />
            <Controller
              name="resetLevel"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  sx={CONTROL_CLS}
                  label="Reset Level, %"
                  type="text"
                  warning={dirtyFields.resetLevel}
                  error={Boolean(errors.resetLevel)}
                  message={errors.resetLevel?.message}
                />
              )}
            />
          </ControlsRow>
        )}
      </ControlsContainer>
    </form>
  );
};
