import { boolean, object, string } from 'yup';

export const VALIDATION_SCHEMA = object().shape({
  action: string().required('Action is a required field'),
  autoExecute: boolean().nullable(),
  closeOutTresholdLevel: string().required(
    'CloseOutThresholdLevel is a required field',
  ),
  func: string().required('Function is a required field'),
  mark: string().required('Mark is a required field'),
  mark_context: string().nullable(),
  name: string().required('Name is a required field'),
  resetLevel: string().required('ResetLevel is a required field'),
  value: string().required('Value is a required field'),
});
