import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.color.typo.primary,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const ErrorTitle = styled('div')(() => ({
  fontSize: '1.7rem',
}));
