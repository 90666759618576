import axios from 'axios';

import { TErrorInfo } from '../../types';

export function transformErrorToInfoObject(error: unknown): TErrorInfo | null {
  if (axios.isAxiosError(error)) {
    const response = error.response;

    if (response) {
      const { data, request, status, statusText } = response;

      return {
        message: data?.description?.value || data?.message || statusText,
        statusCode: data?.statusCode || status,
        url: request.responseURL,
      };
    }
  }

  if (error instanceof Error) {
    return { message: `${error.name}: ${error.message}` };
  }

  return null;
}
