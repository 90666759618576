import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Notification } from 'react-ui-kit-exante';

import { FOREVER, ONE_HOUR_IN_SECONDS } from 'pages/RiskEvent/constants';
import {
  RiskEventActions,
  RiskEventActionsType,
  RiskEventState,
} from 'pages/RiskEvent/context';
import { forceCloseEventService } from 'resources';
import { TFormUpdateForceCloseEvent } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { VALIDATION_SCHEMA } from '../validationSchema';

type UsePostponeFormProps = {
  dispatch: React.Dispatch<RiskEventActionsType>;
  refetch: () => Promise<void>;
  state: RiskEventState;
};

export const usePostponeForm = ({
  dispatch,
  refetch,
  state,
}: UsePostponeFormProps) => {
  const methods = useForm({
    defaultValues: {
      shiftImmunity: ONE_HOUR_IN_SECONDS,
      internalComment: '',
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const { control, getValues } = methods;

  const handlePostponeConfirm = async () => {
    try {
      const { shiftImmunity, internalComment }: TFormUpdateForceCloseEvent =
        getValues();

      const payload: TFormUpdateForceCloseEvent = {
        shiftImmunity,
        internalComment,
      };

      if (shiftImmunity === FOREVER) {
        payload.immunityDeadline = new Date('2099-12-31').toISOString();

        delete payload.shiftImmunity;
      }

      await forceCloseEventService.updateForceCloseEvent(
        state.postponeModal.number,
        payload,
      );

      Notification.success({
        title: 'Force Close Event successfully postponed',
      });

      dispatch({
        type: RiskEventActions.ForceCloseEventPostponeModal,
        payload: {
          number: '',
          show: false,
        },
      });

      refetch();
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  };

  const handleModalClose = () => {
    dispatch({
      type: RiskEventActions.ForceCloseEventPostponeModal,
      payload: {
        number: '',
        show: false,
      },
    });
  };

  return {
    control,
    handleModalClose,
    handlePostponeConfirm,
  };
};
