import { AxiosResponse } from 'axios';

import type { TForceCloseRule, TSwapForceCloseRuleOrders } from 'types';

import { ForceCloseRuleRepository } from './forceCloseRule.repository';
import { transformParams } from './helpers';

export class ForceCloseRuleService {
  public async fetchForceCloseRules(): Promise<TForceCloseRule[]> {
    const { data } = await ForceCloseRuleRepository.fetchForceCloseRules();

    return data.sort((a, b) => {
      if (!a.order || !b.order) {
        return 0;
      }

      return a.order - b.order;
    });
  }

  public async fetchForceCloseRuleById(id: number): Promise<TForceCloseRule> {
    try {
      const { data } = await ForceCloseRuleRepository.fetchForceCloseRuleById(
        id,
      );

      return data;
    } catch (err) {
      return {} as TForceCloseRule;
    }
  }

  public async createForceCloseRule(
    payload: TForceCloseRule,
  ): Promise<AxiosResponse> {
    return ForceCloseRuleRepository.createForceCloseRule(
      transformParams(payload),
    );
  }

  public async updateForceCloseRule(
    id: number,
    payload: TForceCloseRule,
  ): Promise<AxiosResponse> {
    return ForceCloseRuleRepository.updateForceCloseRule(
      id,
      transformParams(payload),
    );
  }

  public async deleteForceCloseRule(id: number): Promise<AxiosResponse> {
    return ForceCloseRuleRepository.deleteForceCloseRule(id);
  }

  public async swapForceCloseRuleOrder(
    payload: TSwapForceCloseRuleOrders,
  ): Promise<AxiosResponse> {
    return ForceCloseRuleRepository.swapForceCloseRuleOrder(payload);
  }
}
