import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modal } from 'react-ui-kit-exante';

import { RefreshButton } from 'components';
import { DEFAULT_POLLING_INTERVAL_20 } from 'constants/common';
import { useInterval } from 'hooks';
import { prepareTableId } from 'utils';

import { LiquidationEstimate } from '../LiquidationEstimate';
import { RiskEventContext } from '../context';

import { Postpone } from './Postpone';
import {
  DEFAULT_SORTING,
  DISPLAYED_COLUMN_KEYS,
  PAGE_SIZE,
  PAGE_SIZES,
} from './constants';
import { useForceCloseEventsList } from './hooks';
import {
  AutoLiquidateIconButton,
  ModalAsterisk,
  ModalNote,
  TableContainer,
} from './styled';

export const ForceCloseEventsList = () => {
  const { state, dispatch } = useContext(RiskEventContext);
  const [isRefreshBtnClicked, setIsRefreshBtnClicked] = useState(false);
  const liquidationEstimateRef = useRef(null);
  const tableId = prepareTableId('forceCloseEventsList');

  const {
    columns,
    data,
    filteringProps,
    getCellProps,
    handleAutoLiquidate,
    handleAutoLiquidateConfirm,
    handleCellClick,
    handleModalClose,
    handleSelectRow,
    hasRows,
    isLoading,
    modalOpened,
    refetch,
    selectedRows,
    serverPaginationProps,
    setSelectedRows,
    setSorting,
  } = useForceCloseEventsList({
    dispatch,
    tableId,
  });

  useInterval(() => {
    setSelectedRows([]);
    refetch();
  }, DEFAULT_POLLING_INTERVAL_20);

  const handleClickOnRefresh = useCallback(() => {
    setIsRefreshBtnClicked(true);

    return refetch();
  }, [refetch]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'RefreshPage',
        component: (
          <RefreshButton loading={isLoading} onRefresh={handleClickOnRefresh} />
        ),
      },
    ],
    [isLoading, handleClickOnRefresh],
  );

  const autoLiquidate = () => (
    <AutoLiquidateIconButton
      disabled={!hasRows}
      iconColor="action"
      iconName="AutoLiquidateAllIcon"
      iconSize={24}
      label="Auto Liquidate"
      onClick={handleAutoLiquidate}
    />
  );

  useEffect(() => {
    if (isRefreshBtnClicked && !isLoading) {
      setIsRefreshBtnClicked(false);
    }
  }, [isLoading, isRefreshBtnClicked]);

  const isInitialLoading = !data && isLoading;

  return (
    <>
      <TableContainer
        additionalActions={additionalActions}
        bulkActions={{
          actions: [autoLiquidate],
          selectedRows,
          setSelectedRows: handleSelectRow,
        }}
        columns={columns}
        data={data?.forceCloseEvents || []}
        defaultSortBy={DEFAULT_SORTING}
        displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
        filteringProps={filteringProps}
        getCellProps={getCellProps}
        handleCellClick={handleCellClick}
        hasFilters
        hasPagination
        isFlexLayout
        isLoading={isInitialLoading || isRefreshBtnClicked}
        manualSortBy
        onSort={setSorting}
        pageSize={PAGE_SIZE}
        pageSizes={PAGE_SIZES}
        saveColumnOrder
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        showTableInfo
        showScrollbar
        tableId={tableId}
      />

      {state.forceCloseEventNumber && (
        <LiquidationEstimate
          forceCloseEventsList={data?.forceCloseEvents || []}
          number={state.forceCloseEventNumber}
          liquidationEstimateRef={liquidationEstimateRef}
        />
      )}

      {state.postponeModal.number && state.postponeModal.show && (
        <Postpone refetch={refetch} />
      )}

      <Modal
        isOpened={modalOpened}
        onClose={handleModalClose}
        title="Auto Liquidate"
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleAutoLiquidateConfirm,
        }}
      >
        <div>Are you sure you want to auto liquidate the event(s)?</div>
        <ModalNote>
          Only events with the status active or passive can be sent to
          liquidation. Events with the status close will not be sent to the auto
          liquidation
          <ModalAsterisk>&#42;</ModalAsterisk>
        </ModalNote>
      </Modal>
    </>
  );
};
