import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  height: '24px',
  width: '24px',

  '& svg': {
    color: `${theme.color.icon.action} !important`,
  },
}));
