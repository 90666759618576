import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { ROUTES } from 'constants/routes';
import { forceCloseRuleService } from 'resources';
import { TForceCloseRule } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import { TFormForceCloseRule } from '../../types';
import { BASE_RULE_ID, PERCENT } from '../constants';
import { VALIDATION_SCHEMA } from '../validationSchema';

export const useForceCloseRuleEditForm = () => {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const data = locationState as TForceCloseRule;

  const handleClose = useCallback(() => {
    navigate(ROUTES.RISK_EVENT);
  }, [navigate]);

  if (isEmpty(locationState)) {
    handleClose();
  }

  const methods = useForm({
    defaultValues: {
      ...data,
      closeOutTresholdLevel: String(
        ((data?.closeOutTresholdLevel || 1.1) * PERCENT).toFixed(2),
      ),
      resetLevel: String(((data?.resetLevel || 1) * PERCENT).toFixed(2)),
    } as TFormForceCloseRule,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
  } = methods;

  const onSubmit = async (payload: TFormForceCloseRule) => {
    try {
      await forceCloseRuleService.updateForceCloseRule(BASE_RULE_ID, {
        ...payload,
        closeOutTresholdLevel: Number(payload.closeOutTresholdLevel) / 100,
        resetLevel: Number(payload.resetLevel) / 100,
      });

      Notification.success({
        title: 'Force Close Rule successfully updated',
      });

      handleClose();
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  };

  return {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleSubmit,
    isDirty,
    onSubmit,
  };
};
