import {
  getBaseAuthDBUrl,
  getBaseLiquidationEstimateUrl,
  getBaseRemusUrl,
} from './getBaseUrl';

export const getAPIHost = () => `https://${getBaseRemusUrl()}`;

export const getAuthDBHost = () => `https://${getBaseAuthDBUrl()}`;

export const getLiquidationEstimateHost = () =>
  `https://${getBaseLiquidationEstimateUrl()}`;
