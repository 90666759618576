import { RiskEventState } from './types';

export const initialState: RiskEventState = {
  errors: '',

  ready: false,
  dirty: false,

  fetchForceCloseRuleStatus: {
    error: null,
    pending: false,
    succeed: false,
  },

  fetchForceCloseEventStatus: {
    error: null,
    pending: false,
    succeed: false,
  },

  forceCloseEventNumber: null,

  fetchForceCloseEventsListStatus: {
    error: null,
    pending: false,
    succeed: false,
  },

  fetchPostponeStatus: {
    error: null,
    pending: false,
    succeed: false,
  },

  fetchLiquidationEstimateStatus: {
    error: null,
    pending: false,
    succeed: false,
  },

  postponeModal: {
    number: '',
    show: false,
  },

  resolutionModal: {
    number: '',
    show: false,
  },
  commentModal: {
    number: '',
    show: false,
  },

  markFunctions: [],
};
