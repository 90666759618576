import { IRow } from 'react-ui-kit-exante';

import type { TForceCloseRule, TSwapForceCloseRuleOrders } from 'types';

export const prepareSwapRowsPayload = (
  direction: string,
  row: IRow<TForceCloseRule>,
  rows: IRow<TForceCloseRule>[],
): TSwapForceCloseRuleOrders | null => {
  const {
    values: { id, order },
  } = row;

  if (id === 0) {
    return null;
  }

  const payloadFirst = {
    id,
    order: null,
  };

  const swapRowIndex = rows.findIndex((rowItem) => {
    const { values } = rowItem;

    if (values.id === id) {
      return true;
    }

    return false;
  });

  const nextRow = rows[swapRowIndex + 1];
  const prevRow = rows[swapRowIndex - 1];

  if (direction === 'down' && nextRow?.values?.id > 0) {
    const swapRowValues = nextRow.values;

    payloadFirst.order = swapRowValues.order;
    const payloadSecond = {
      id: swapRowValues.id,
      order,
    };

    return {
      orders: [payloadFirst, payloadSecond],
    };
  }

  if (direction === 'up' && prevRow) {
    const swapRowValues = prevRow.values;

    payloadFirst.order = swapRowValues.order;
    const payloadSecond = {
      id: swapRowValues.id,
      order,
    };

    return {
      orders: [payloadFirst, payloadSecond],
    };
  }

  return null;
};
