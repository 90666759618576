import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { Button, Modal } from 'react-ui-kit-exante';

import { RiskEventContext } from '../../context';
import { ControlsInputMultiline, FormActions } from '../styled';

import { useCommentForm } from './hooks';

type CommentProps = {
  refetch: () => Promise<void>;
};

export const Comment = ({ refetch }: CommentProps) => {
  const { state, dispatch } = useContext(RiskEventContext);

  const { control, errors, isValid, handleModalClose, handleSubmit, onSubmit } =
    useCommentForm({
      dispatch,
      refetch,
      state,
    });

  return (
    <Modal
      cancelButton={{
        hidden: true,
      }}
      isOpened={state.commentModal.show}
      onClose={handleModalClose}
      title="Add Comment"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="internalComment"
          control={control}
          render={({ field }) => (
            <ControlsInputMultiline
              {...field}
              label="Comment"
              multiline
              rows={4}
              value={field.value}
              error={Boolean(errors.internalComment)}
              message={errors.internalComment?.message}
            />
          )}
        />
        <FormActions>
          <Button color="secondary" onClick={handleModalClose} type="button">
            Close
          </Button>
          <Button disabled={!isValid} type="submit">
            Confirm
          </Button>
        </FormActions>
      </form>
    </Modal>
  );
};
