import {
  ICellValue,
  IColumn,
  IconButton,
  formatDate,
} from 'react-ui-kit-exante';

import {
  FORCE_CLOSE_EVENT_STATE,
  ForceCloseEventStateOptions,
} from 'constants/common';
import { ForceCloseEventState, type TForceCloseEvent } from 'types';
import {
  formatNumberToFinancial,
  resetTablePage,
  transferValueToPercent,
} from 'utils';

import { DATE_TIME_WITH_SECONDS } from './constants';
import { CheckIconContainer } from './styled';

export type TGetColumnsProps = {
  handlePostpone: (rowValue: unknown) => void;
  onFilter: (column: string, value: unknown) => void;
  onRemove: (column: string) => void;
  tableId: string;
};

export const getColumns = ({
  handlePostpone,
  onFilter,
  onRemove,
  tableId,
}: TGetColumnsProps): IColumn<TForceCloseEvent> => [
  {
    Header: 'Client ID',
    accessor: 'clientId',
    type: 'textInput',
    onFilter: (column: string, value: unknown) => {
      resetTablePage(tableId);
      onFilter(column, value);
    },
    onRemove,
    minWidth: 150,
    maxWidth: 175,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      original.clientId || '',
  },
  {
    Header: 'Liquidation',
    accessor: 'autoExecute',
    disableFilters: true,
    disableSortBy: true,
    minWidth: 75,
    maxWidth: 100,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      original.autoExecute ? <CheckIconContainer /> : null,
  },
  {
    Header: 'Group ID',
    accessor: 'groupId',
    onFilter: (column: string, value: unknown) => {
      resetTablePage(tableId);
      onFilter(column, value);
    },
    onRemove,
    minWidth: 225,
    maxWidth: 250,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      original.groupId || '',
  },
  {
    Header: 'Number',
    accessor: 'number',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    minWidth: 275,
    maxWidth: 300,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      original.number || '',
  },
  {
    Header: 'Legal Entity',
    accessor: 'legalEntity',
    disableFilters: true,
    disableSortBy: true,
    minWidth: 100,
    maxWidth: 125,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      original.legalEntity || '',
  },
  {
    Header: 'State',
    accessor: 'state',
    disableSortBy: true,
    filterOptions: ForceCloseEventStateOptions,
    type: 'multiSelect',
    onFilter: (column: string, value: unknown) => {
      resetTablePage(tableId);
      onFilter(column, value);
    },
    onRemove,
    minWidth: 75,
    maxWidth: 100,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      original.state
        ? ForceCloseEventState[
            original.state as keyof typeof ForceCloseEventState
          ]
        : '',
  },
  {
    Header: 'Ownership Types',
    accessor: 'ownershipTypes',
    disableFilters: true,
    disableSortBy: true,
    minWidth: 125,
    maxWidth: 150,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      original.ownershipTypes.join(', ') || '',
  },
  {
    Header: 'Updated At',
    accessor: 'updatedAt',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            format: DATE_TIME_WITH_SECONDS,
          }),
      },
    },
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      formatDate({
        date: original.updatedAt,
        format: DATE_TIME_WITH_SECONDS,
      }),
  },
  {
    Header: 'Inserted At',
    accessor: 'insertedAt',
    onFilter: (id: string, values: Date[]) => {
      let [fromDate, toDate] = values;

      const [from, to] = values;
      const isCreateTimeFromYear1970 = new Date(from).getFullYear() === 1970;
      const isCreateTimeToYear1970 = new Date(to).getFullYear() === 1970;

      if (isCreateTimeFromYear1970) {
        fromDate = toDate;
      } else {
        fromDate = from;
      }

      if (isCreateTimeToYear1970) {
        toDate = new Date();
      } else {
        toDate = to;
      }

      resetTablePage(tableId);
      onFilter(id, [fromDate, toDate]);
    },
    onRemove,
    onToday: onFilter,
    onYesterday: onFilter,
    onLastWeek: onFilter,
    type: 'dateRange',
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            format: DATE_TIME_WITH_SECONDS,
          }),
      },
    },
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      formatDate({
        date: original.insertedAt,
        format: DATE_TIME_WITH_SECONDS,
      }),
  },
  {
    Header: 'Actual Margin',
    accessor: 'actualMargin',
    disableFilters: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      formatNumberToFinancial(original.actualMargin),
  },
  {
    Header: 'Actual NAV',
    accessor: 'actualNAV',
    disableFilters: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      formatNumberToFinancial(original.actualNAV),
  },
  {
    Header: 'Actual MU, %',
    accessor: 'actualMU',
    disableFilters: true,
    formatting: 'number',
    type: 'numberRange',
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) => {
      if (Number.isNaN(Number(original.actualMU))) {
        return '';
      }

      return transferValueToPercent(original, 'actualMU');
    },
  },
  {
    Header: 'Actual Rule',
    accessor: 'actualRule',
    disableFilters: true,
    formatting: 'number',
  },
  {
    Header: 'Actual TS',
    accessor: 'actualTS',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            format: DATE_TIME_WITH_SECONDS,
          }),
      },
    },
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      formatDate({
        date: original.actualTS,
        format: DATE_TIME_WITH_SECONDS,
      }),
  },
  {
    Header: 'Actual Rule TS',
    accessor: 'actualRuleTS',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            format: DATE_TIME_WITH_SECONDS,
          }),
      },
    },
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      formatDate({
        date: original.actualRuleTS,
        format: DATE_TIME_WITH_SECONDS,
      }),
  },
  {
    Header: 'Initial Margin',
    accessor: 'initialMargin',
    disableFilters: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      formatNumberToFinancial(original.initialMargin),
  },
  {
    Header: 'Initial NAV',
    accessor: 'initialNAV',
    disableFilters: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      formatNumberToFinancial(original.initialNAV),
  },
  {
    Header: 'Initial MU, %',
    accessor: 'initialMU',
    disableFilters: true,
    formatting: 'number',
    type: 'numberRange',
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) => {
      if (Number.isNaN(Number(original.initialMU))) {
        return '';
      }

      return transferValueToPercent(original, 'initialMU');
    },
  },
  {
    Header: 'Initial Rule',
    accessor: 'initialRule',
    disableFilters: true,
    formatting: 'number',
  },
  {
    Header: 'Initial TS',
    accessor: 'initialTS',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            format: DATE_TIME_WITH_SECONDS,
          }),
      },
    },
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      formatDate({
        date: original.initialTS,
        format: DATE_TIME_WITH_SECONDS,
      }),
  },
  {
    Header: 'Initial Rule TS',
    accessor: 'initialRuleTS',
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) =>
          formatDate({
            date: value,
            format: DATE_TIME_WITH_SECONDS,
          }),
      },
    },
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      formatDate({
        date: original.initialRuleTS,
        format: DATE_TIME_WITH_SECONDS,
      }),
  },
  {
    Header: 'Comment',
    accessor: 'internalComment',
    disableFilters: true,
    disableSortBy: true,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    minWidth: 250,
    maxWidth: 300,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) =>
      original.internalComment || '',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row: { original } }: ICellValue<TForceCloseEvent>) => {
      const { autoExecute, state } = original;

      return (
        <IconButton
          disabled={autoExecute || state === FORCE_CLOSE_EVENT_STATE.close}
          iconColor="primary"
          iconName="PostponeIcon"
          iconSize={16}
          onClick={() => handlePostpone(original)}
        />
      );
    },
  },
];
