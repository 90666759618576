const isInsideRun = () => window.runUIhistoryContainer && window.REMUS_UI;

const defaultURLs = {
  remus: 'remus-stage.exante.eu',
  authDB: 'authdb-stage.exante.eu',
  liquidationEstimate: 'liquidation-estimate-stage.exante.eu',
};

const getWarnMsg = (
  title: string,
  variableName: string,
  envVariableName: string,
) => {
  return `[${title} Warning] REMUS application was run inside the RUN-UI, but ${variableName} is empty, so the ${envVariableName} from the environment variables is used as a fallback`;
};

export const getBaseRemusUrl = (fallback = defaultURLs.remus) => {
  if (isInsideRun()) {
    const url = window.REMUS_UI?.api_urls?.REMUS;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseRemusUrl',
          'window.REMUS_UI.api_urls.REMUS',
          'process.env.REACT_APP_REMUS_API',
        ),
      );
      return process.env.REACT_APP_REMUS_API || fallback;
    }

    return url;
  }

  return process.env.REACT_APP_REMUS_API || fallback;
};

export const getBaseAuthDBUrl = (fallback = defaultURLs.authDB) => {
  if (isInsideRun()) {
    const url = window.REMUS_UI?.api_urls?.AUTHDB;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseAuthDBUrl',
          'window.REMUS_UI.api_urls.AUTHDB',
          'process.env.REACT_APP_AUTHDB_API',
        ),
      );
      return process.env.REACT_APP_AUTHDB_API || fallback;
    }

    return url;
  }

  return process.env.REACT_APP_AUTHDB_API || fallback;
};

export const getBaseLiquidationEstimateUrl = (
  fallback = defaultURLs.liquidationEstimate,
) => {
  if (isInsideRun()) {
    const url = window.REMUS_UI?.api_urls?.LIQUIDATION_ESTIMATE;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseLiquidationEstimateUrl',
          'window.REMUS_UI.api_urls.LIQUIDATION_ESTIMATE',
          'process.env.REACT_APP_LIQUIDATION_ESTIMATE_API',
        ),
      );
      return process.env.REACT_APP_LIQUIDATION_ESTIMATE_API || fallback;
    }

    return url;
  }

  return process.env.REACT_APP_LIQUIDATION_ESTIMATE_API || fallback;
};
