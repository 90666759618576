import { RiskEventState } from '../types';

export const onFetchForceCloseEventSucceed = (
  state: RiskEventState,
): RiskEventState => ({
  ...state,

  ready: true,

  fetchForceCloseEventStatus: {
    error: null,
    pending: false,
    succeed: true,
  },
});
