import { Container, ErrorTitle } from './styled';

type TErrorFallbackProps = {
  error: Error;
  resetErrorBoundary(): void;
};

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: TErrorFallbackProps) => {
  return (
    <div role="alert">
      <Container>
        <ErrorTitle>Something has gone wrong:</ErrorTitle>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </Container>
    </div>
  );
};
