import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Notification } from 'react-ui-kit-exante';

import { FOREVER } from 'pages/RiskEvent/constants';
import { forceCloseEventService } from 'resources';
import type { TUpdateForceCloseEvent } from 'types';
import { sendUiKitErrorNotification } from 'utils';

import {
  RiskEventActions,
  RiskEventActionsType,
  RiskEventState,
} from '../../../context';
import { INITIAL_VALUES, Resolutions } from '../constants';
import { VALIDATION_SCHEMA } from '../validationSchema';

import type { TResolutionForm } from './types';

type UseResolutionFormProps = {
  dispatch: React.Dispatch<RiskEventActionsType>;
  refetch: () => Promise<void>;
  state: RiskEventState;
};

export const useResolutionForm = ({
  dispatch,
  refetch,
  state,
}: UseResolutionFormProps) => {
  const methods = useForm({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = methods;

  const onSubmit = async (payload: TResolutionForm) => {
    try {
      const payloadClone = { ...payload };

      if (!payloadClone.shiftImmunity) {
        delete payloadClone.shiftImmunity;
      }

      if (payloadClone.shiftImmunity?.value === FOREVER) {
        payloadClone.immunityDeadline = new Date('2099-12-31').toISOString();

        delete payloadClone.shiftImmunity;
      }

      const updatedPayload: TUpdateForceCloseEvent = {
        immunityDeadline: payloadClone.immunityDeadline,
        internalComment: payloadClone.internalComment,
        resolution: payloadClone.resolution,
        shiftImmunity: payloadClone.shiftImmunity?.value,
        ...(payloadClone.resolution === Resolutions.Negative && {
          immunityDeadline: null,
        }),
      };

      await forceCloseEventService.updateForceCloseEvent(
        state.resolutionModal.number,
        updatedPayload,
      );

      Notification.success({
        title: 'Resolution successfully confirmed',
      });

      dispatch({
        type: RiskEventActions.PostponeResolutionModal,
        payload: {
          number: '',
          show: false,
        },
      });

      refetch();
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  };

  const handleModalClose = () => {
    dispatch({
      type: RiskEventActions.PostponeResolutionModal,
      payload: {
        number: '',
        show: false,
      },
    });
  };

  return {
    control,
    errors,
    isValid,
    handleModalClose,
    handleSubmit,
    onSubmit,
  };
};
