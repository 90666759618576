import { Radio as RadioMui } from '@mui/material';

import { blockNonNativeProps } from '../../../../helpers';
import { styled } from '../../../../theme';

import { IRadioProps } from './Radio.types';

export const RadioStyled = styled(RadioMui, {
  shouldForwardProp: blockNonNativeProps('warning'),
})<IRadioProps>(({ warning, theme, checked }) => {
  const checkedColor = warning
    ? theme.color.icon.warning
    : theme.color.icon.action;

  const color = warning ? theme.color.icon.warning : theme.color.icon.secondary;
  return {
    padding: '0 7px 0 0',

    svg: {
      color: checked ? checkedColor : color,
    },
  };
});
