import { TResolutionModal } from 'pages/RiskEvent/types';

import { RiskEventState } from '../types';

export const onPostponeToggleResolutionModal = (
  state: RiskEventState,
  payload: TResolutionModal,
): RiskEventState => ({
  ...state,

  resolutionModal: payload,
});
