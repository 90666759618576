export const DISPLAYED_COLUMN_KEYS = [
  'accountId',
  'internalComment',
  'immunityDeadline',
  'number',
  'state',
  'actualMargin',
  'actualNAV',
  'actualMU',
  'actions',
];
export const DEFAULT_SORTING = [{ id: 'accountId', desc: false }];
export const PAGE_SIZE = 20;
export const PAGE_SIZES = [20, 50, 100];
export const DATE_TIME_WITH_SECONDS = 'yyyy-MM-dd, HH:mm:ss';
export const CONTROL_CLS = { mt: '16px', width: '100%' };
