import { useLayoutEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import { NotificationProvider, GlobalStyles } from 'react-ui-kit-exante';

import { ErrorFallback, Header } from './components';
import { ROUTES } from './constants';
import { RiskEvent } from './pages';
import { getBaseName } from './utils/getBaseName';

import './index.css';

export const App = () => {
  const history = window.runUIhistoryContainer;

  const [navigator, setNavigator] = useState({
    action: history?.action,
    location: history?.location,
  });

  const isInsideRun = !!history;
  const baseName = getBaseName();

  const routes = (
    <Routes>
      <Route path={`${ROUTES.RISK_EVENT}/*`} element={<RiskEvent />} />
    </Routes>
  );

  useLayoutEffect(() => {
    if (history) {
      history.listen(() => {
        return setNavigator({
          action: history.action,
          location: history.location,
        });
      });
    }
  }, [history]);

  return (
    <>
      <NotificationProvider />
      {isInsideRun ? (
        <Router
          location={navigator.location}
          navigationType={navigator.action}
          navigator={history}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <GlobalStyles />
            {routes}
          </ErrorBoundary>
        </Router>
      ) : (
        <BrowserRouter basename={baseName}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <GlobalStyles />
            <Header />
            {routes}
          </ErrorBoundary>
        </BrowserRouter>
      )}
    </>
  );
};
