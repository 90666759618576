/* eslint-disable import/no-mutable-exports */

import { ForceCloseEventService } from './forceCloseEvent';
import { ForceCloseRuleService } from './forceCloseRule';
import { LiquidationEstimateService } from './liquidationEstimate';
import { MarkFunctionService } from './markFunction';
import { PostponeService } from './postpone';

let forceCloseEventService: ForceCloseEventService;
let forceCloseRuleService: ForceCloseRuleService;
let liquidationEstimateService: LiquidationEstimateService;
let markFunctionService: MarkFunctionService;
let postponeService: PostponeService;

export function initResources() {
  forceCloseEventService = new ForceCloseEventService();
  forceCloseRuleService = new ForceCloseRuleService();
  liquidationEstimateService = new LiquidationEstimateService();
  markFunctionService = new MarkFunctionService();
  postponeService = new PostponeService();
}

export {
  forceCloseEventService,
  forceCloseRuleService,
  liquidationEstimateService,
  markFunctionService,
  postponeService,
};
