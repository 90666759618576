import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { RefreshButton } from 'components';
import { DEFAULT_POLLING_INTERVAL_20 } from 'constants/common';
import { useInterval } from 'hooks';
import { prepareTableId } from 'utils';

import { RiskEventContext } from '../context';

import { Comment } from './Comment';
import { Resolution } from './Resolution';
import {
  DEFAULT_SORTING,
  DISPLAYED_COLUMN_KEYS,
  PAGE_SIZE,
  PAGE_SIZES,
} from './constants';
import { usePostpones } from './hooks';
import { TableContainer } from './styled';

export const Postpones = () => {
  const { state, dispatch } = useContext(RiskEventContext);
  const [isRefreshBtnClicked, setIsRefreshBtnClicked] = useState(false);
  const tableId = prepareTableId('postpone');

  const {
    columns,
    data,
    filteringProps,
    handleComment,
    handleResolution,
    isLoading,
    refetch,
    serverPaginationProps,
  } = usePostpones({
    dispatch,
    tableId,
  });

  useInterval(() => refetch(), DEFAULT_POLLING_INTERVAL_20);

  const rowActions = useMemo(
    () => ({
      show: true,
      hideEdit: true,
      additionalActions: [
        {
          label: (
            <IconButton
              iconColor="primary"
              iconName="ResolutionIcon"
              iconSize={18}
            />
          ),
          title: 'Resolution Modal',
          onClick: handleResolution,
        },
        {
          label: (
            <IconButton
              iconColor="primary"
              iconName="AddCommentIcon"
              iconSize={18}
            />
          ),
          title: 'Comment Modal',
          onClick: handleComment,
        },
      ],
    }),
    [handleComment, handleResolution, isLoading],
  );

  const handleClickOnRefresh = useCallback(() => {
    setIsRefreshBtnClicked(true);

    refetch();
  }, [refetch]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'RefreshPage',
        component: (
          <RefreshButton loading={isLoading} onRefresh={handleClickOnRefresh} />
        ),
      },
    ],
    [isLoading, handleClickOnRefresh],
  );

  useEffect(() => {
    if (isRefreshBtnClicked && !isLoading) {
      setIsRefreshBtnClicked(false);
    }
  }, [isLoading, isRefreshBtnClicked]);

  const isInitialLoading = !data && isLoading;

  return (
    <>
      <TableContainer
        additionalActions={additionalActions}
        columns={columns}
        data={data?.postpones || []}
        defaultSortBy={DEFAULT_SORTING}
        displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
        filteringProps={filteringProps}
        hasFilters
        hasPagination
        isFlexLayout
        isLoading={isInitialLoading || isRefreshBtnClicked}
        isPinnedHeader
        pageSize={PAGE_SIZE}
        pageSizes={PAGE_SIZES}
        rowActions={rowActions}
        saveColumnOrder
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        showTableInfo
        showScrollbar
        tableId={tableId}
      />

      {state.resolutionModal.number && state.resolutionModal.show && (
        <Resolution refetch={refetch} />
      )}

      {state.commentModal.number && state.commentModal.show && (
        <Comment refetch={refetch} />
      )}
    </>
  );
};
