import { isValidDate } from './isValidDate';

export function convertDateToUTC(dateString: string | Date) {
  const date = new Date(dateString);

  if (!isValidDate(date)) {
    return new Date();
  }

  const utcField = {
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };

  return new Date(
    Date.UTC(
      utcField.year,
      utcField.month,
      utcField.date,
      utcField.hours,
      utcField.minutes,
    ),
  );
}
