import { css } from '@emotion/react';

// eslint-disable-next-line import/no-unresolved
import FoxSansProBold from '../../assets/fonts/FoxSansPro-Bold.woff2';
// eslint-disable-next-line import/no-unresolved
import FoxSansPro from '../../assets/fonts/FoxSansPro.woff2';
// eslint-disable-next-line import/no-unresolved
import FoxSansProLt from '../../assets/fonts/FoxSansProLt.woff2';
// eslint-disable-next-line import/no-unresolved
import InterBold from '../../assets/fonts/Inter-Bold.woff2';
// eslint-disable-next-line import/no-unresolved
import InterMedium from '../../assets/fonts/Inter-Medium.woff2';
// eslint-disable-next-line import/no-unresolved
import InterRegular from '../../assets/fonts/Inter-Regular.woff2';
// eslint-disable-next-line import/no-unresolved
import InterSemiBold from '../../assets/fonts/Inter-SemiBold.woff2';

export const fontFaces = css`
  @font-face {
    font-family: 'FoxSansPro';
    src: url(${FoxSansPro}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'FoxSansPro';
    src: url(${FoxSansProBold}) format('woff2');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'FoxSansPro';
    src: url(${FoxSansProLt}) format('woff2');
    font-weight: 300; /* Light */
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterRegular}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterMedium}) format('woff2');
    font-weight: 500; /* Medium */
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterSemiBold}) format('woff2');
    font-weight: 600; /* Semi-Bold */
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterBold}) format('woff2');
    font-weight: bold;
    font-style: normal;
  }
`;
