import { ISortBy } from 'react-ui-kit-exante';

export const DISPLAYED_COLUMN_KEYS = [
  'autoExecute',
  'groupId',
  'clientId',
  'number',
  'legalEntity',
  'state',
  'ownershipTypes',
  'actualMargin',
  'actualNAV',
  'actualMU',
  'initialMargin',
  'initialNAV',
  'initialMU',
  'actions',
];
export const PAGE_SIZE = 20;
export const PAGE_SIZES = [10, 20, 50];
export const DATE_TIME_WITH_SECONDS = 'yyyy-MM-dd, HH:mm:ss';

export function getDefaultSorting(): ISortBy[] {
  return [{ id: 'groupId', desc: false }];
}
export const DEFAULT_SORTING = getDefaultSorting();

export const getDefaultPagination = () => ({
  limit: PAGE_SIZE,
  skip: 0,
});
