export const DISPLAYED_COLUMN_KEYS = [
  'order',
  'name',
  'func',
  'mark_context',
  'mark',
  'value',
  'autoExecute',
  'action',
  'closeOutTresholdLevel',
  'resetLevel',
  'actions',
];
export const DATE_TIME_WITH_SECONDS = 'yyyy-MM-dd, HH:mm:ss';
