import { FilterValue, TAdditionalFilter } from 'react-ui-kit-exante';

import { resetTablePage } from 'utils';

type TGetAdditionalFiltersProps = {
  filters: Record<string, unknown>;
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  tableId: string;
};

export function getAdditionalFilters<Data extends Record<string, unknown>>({
  filters,
  onFilter,
  onRemove,
  tableId,
}: TGetAdditionalFiltersProps): TAdditionalFilter<Data>[] {
  if (filters.accountIds) {
    const accountIDs = filters.accountIds as string;

    onFilter('accountIds', accountIDs.toString().replace(/\s+/g, ''));
  }

  return [
    {
      Header: 'Account IDs',
      accessor: 'accountIds',
      onFilter: (column: string, value: FilterValue) => {
        resetTablePage(tableId);
        onFilter(column, value);
      },
      onRemove,
    },
  ];
}
