export const INITIAL_VALUES = {
  resolution: '',
  internalComment: '',
  shiftImmunity: {
    label: '',
    value: 0,
  },
};

export enum Resolutions {
  Positive = 'Positive',
  Negative = 'Negative',
}

export const ResolutionsOptions = Object.keys(Resolutions).map(
  (resolution) => ({
    label: resolution,
    value: resolution,
  }),
);

export const CONTROL_CLS = { mt: '16px', width: '100%' };
