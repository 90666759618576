import { useContext, useMemo } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { RefreshButton } from 'components';
import { prepareTableId } from 'utils';

import { RiskEventContext } from '../context';
import { useFetchMarkFunction } from '../hooks';

import { DISPLAYED_COLUMN_KEYS } from './constants';
import { useForceCloseRules } from './hooks';
import { TableContainer } from './styled';

export const ForceCloseRules = () => {
  const { state, dispatch } = useContext(RiskEventContext);
  const tableId = prepareTableId('forceCloseRule');

  useFetchMarkFunction();

  const markOptions: Record<string, string> = useMemo(
    () =>
      state.markFunctions.reduce((acc, item) => {
        return {
          ...acc,
          [item.code]: item.description,
        };
      }, {}),
    [state.markFunctions],
  );

  const {
    columns,
    data,
    handleCellClick,
    handleForceCloseRuleAdd,
    isLoading,
    refetch,
    swapRows,
  } = useForceCloseRules({
    dispatch,
    markOptions,
    tableId,
  });

  const additionalActions = useMemo(
    () => [
      {
        key: 'RefreshPage',
        component: <RefreshButton loading={isLoading} onRefresh={refetch} />,
      },
      {
        key: 'ForceCloseRuleAdd',
        component: (
          <IconButton
            iconColor="action"
            iconName="AddIcon"
            iconSize={24}
            label="Add Rule"
            onClick={handleForceCloseRuleAdd}
          />
        ),
      },
    ],
    [handleForceCloseRuleAdd, isLoading, refetch],
  );

  return (
    <TableContainer
      additionalActions={additionalActions}
      columns={columns}
      data={data || []}
      defaultSortBy={[]}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      handleCellClick={handleCellClick}
      isFlexLayout
      isLoading={isLoading || state.fetchForceCloseRuleStatus.pending}
      isPinnedHeader
      saveColumnOrder
      saveViewParamsAfterLeave
      showTableInfo
      showScrollbar
      swapRows={swapRows}
      tableId={tableId}
    />
  );
};
