import { useCallback, useState } from 'react';
import { IRow, Notification } from 'react-ui-kit-exante';

import { FORCE_CLOSE_EVENT_STATE } from 'constants/common';
import { forceCloseEventService } from 'resources';
import { TForceCloseEvent } from 'types';
import { sendUiKitErrorNotification } from 'utils';

type TUseBulkActionsProps = {
  refetch: () => Promise<void>;
};

export const useBulkActions = ({ refetch }: TUseBulkActionsProps) => {
  const [selectedRows, setSelectedRows] = useState<IRow<TForceCloseEvent>[]>(
    [],
  );
  const [modalOpened, setModalOpened] = useState(false);
  const hasRows = selectedRows.length > 0;

  const handleSelectRow = useCallback(
    (row: IRow<TForceCloseEvent> | IRow<TForceCloseEvent>[]) => {
      setSelectedRows(
        (prev: IRow<TForceCloseEvent>[]): IRow<TForceCloseEvent>[] => {
          if (Array.isArray(row)) {
            return row.map((rowItem) => {
              const { original } = rowItem;
              const { autoExecute } = original;

              return {
                ...rowItem,
                original: {
                  autoExecute: Boolean(autoExecute),
                  ...original,
                },
              };
            });
          }

          const { original } = row;
          const { autoExecute, number } = original;

          const foundIndex = prev.findIndex(
            ({ original: originalPrev }) => originalPrev.number === number,
          );

          if (foundIndex > -1) {
            return prev.filter((_, index) => index !== foundIndex);
          }

          return [
            ...prev,
            {
              ...row,
              original: { autoExecute: Boolean(autoExecute), ...original },
            },
          ];
        },
      );
    },
    [setSelectedRows],
  );

  const handleAutoLiquidate = useCallback(() => {
    setModalOpened(true);
  }, [selectedRows]);

  const handleModalClose = () => {
    setModalOpened(false);
  };

  const handleAutoLiquidateConfirm = async () => {
    try {
      let showWarning = false;

      Promise.all(
        selectedRows
          .filter(({ original }) => {
            if (
              original.state !== FORCE_CLOSE_EVENT_STATE.close &&
              original.autoExecute === false
            ) {
              return true;
            }

            showWarning = true;

            return false;
          })
          .map(({ original }) =>
            forceCloseEventService.updateForceCloseEvent(original.number, {
              autoExecute: true,
            }),
          ),
      )
        .then((values) => {
          if (values.length) {
            Notification.success({
              title: 'Force Close Event(s) successfully auto liquidated',
            });
          }

          if (showWarning) {
            Notification.warning({
              title:
                `Some event(s) were on state close or auto liquidation enabled, ` +
                `because of these reasons they haven't been send to the auto liquidation`,
            });
          }
        })
        .catch((err) => {
          sendUiKitErrorNotification(err);
        })
        .finally(() => {
          refetch();
        });
    } catch (err) {
      sendUiKitErrorNotification(err);
    } finally {
      setModalOpened(false);
      setSelectedRows([]);
    }
  };

  return {
    handleAutoLiquidate,
    handleAutoLiquidateConfirm,
    handleModalClose,
    handleSelectRow,
    hasRows,
    modalOpened,
    selectedRows,
    setSelectedRows,
  };
};
