import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { Modal, Select } from 'react-ui-kit-exante';

import { POSTPONE_OPTIONS } from 'pages/RiskEvent/constants';
import { RiskEventContext } from 'pages/RiskEvent/context';

import { CONTROL_CLS } from './constants';
import { usePostponeForm } from './hooks';
import { ControlsContainer, ControlsInputMultiline } from './styled';

type PostponeProps = {
  refetch: () => Promise<void>;
};

export const Postpone = ({ refetch }: PostponeProps) => {
  const { state, dispatch } = useContext(RiskEventContext);

  const { control, handleModalClose, handlePostponeConfirm } = usePostponeForm({
    dispatch,
    refetch,
    state,
  });

  return (
    <Modal
      isOpened={state.postponeModal.show}
      onClose={handleModalClose}
      title="Postpone"
      confirmButton={{
        confirmButtonName: 'Confirm',
        handleConfirm: handlePostponeConfirm,
      }}
    >
      <ControlsContainer>
        <Controller
          name="shiftImmunity"
          control={control}
          render={({ field }) => (
            <Select
              onChange={field.onChange}
              options={POSTPONE_OPTIONS}
              sx={CONTROL_CLS}
              value={field.value}
            />
          )}
        />
        <Controller
          name="internalComment"
          control={control}
          render={({ field }) => (
            <ControlsInputMultiline
              {...field}
              label="Comment"
              multiline
              rows={4}
              value={field.value}
            />
          )}
        />
      </ControlsContainer>
    </Modal>
  );
};
