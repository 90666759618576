import { styled, IconButton, Table } from 'react-ui-kit-exante';

import { ReactComponent as CircleCheckIcon } from 'assets/circleCheck.svg';
import { TForceCloseEvent } from 'types';

export const TableContainer = styled(Table<TForceCloseEvent>)(() => ({
  marginBottom: '20px',

  '& div[data-rbd-draggable-id="actions"]': {
    flex: '80 0 auto !important',
    minWidth: '80px !important',
    width: 'auto !important',
  },
  '& div[data-test-id="table__body--actions-cell"]': {
    flex: '80 0 auto !important',
    justifyContent: 'center !important',
    minWidth: '80px !important',
    width: '80px !important',
  },
  '& div[data-test-id="table__body--action-cell"]': {
    justifyContent: 'center',
  },
}));

export const CheckIconContainer = styled(CircleCheckIcon)(({ theme }) => ({
  color: theme.color.icon.action,
  height: '20px',
  width: '20px',
}));

export const AutoLiquidateIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.color.icon.action,

  '& .IconButtonLabel': {
    fontSize: '16px',
  },
}));

export const ModalNote = styled('div')(({ theme }) => ({
  color: theme.color.typo.secondary,
  fontSize: '12px',
}));

export const ModalAsterisk = styled('span')(({ theme }) => ({
  color: theme.color.typo.radical,
}));
