import { Controller } from 'react-hook-form';
import { Checkbox, IconButton, Input } from 'react-ui-kit-exante';

import { CONTROL_CLS } from './constants';
import { useForceCloseRuleEditForm } from './hooks';
import {
  ActionsContainer,
  ControlsContainer,
  ControlsRow,
  PanelContainer,
} from './styled';

export const ForceCloseRuleEditBaseRule = () => {
  const {
    control,
    dirtyFields,
    errors,
    handleClose,
    handleSubmit,
    isDirty,
    onSubmit,
  } = useForceCloseRuleEditForm();

  const renderActions = () => (
    <ActionsContainer>
      <IconButton
        disabled={!isDirty}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={24}
        label="Save"
        type="submit"
      />
      <IconButton
        iconColor="secondary"
        iconName="CloseIcon"
        iconSize={28}
        onClick={handleClose}
      />
    </ActionsContainer>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PanelContainer title="Edit Base Rule" action={renderActions()} />

      <ControlsContainer>
        <Controller
          name="id"
          control={control}
          render={({ field }) => (
            <Input {...field} disabled label="Rule ID" sx={CONTROL_CLS} />
          )}
        />
        <Controller
          name="order"
          control={control}
          render={({ field }) => (
            <Input {...field} disabled label="Rule Order" sx={CONTROL_CLS} />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              disabled
              label="Rule Name"
              sx={{
                ...CONTROL_CLS,
                width: '100%',
              }}
            />
          )}
        />
        <Controller
          name="autoExecute"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              label="AutoExecute"
              sx={CONTROL_CLS}
            />
          )}
        />
        <ControlsRow>
          <Controller
            name="closeOutTresholdLevel"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                sx={CONTROL_CLS}
                label="COT Level, %"
                type="text"
                warning={dirtyFields.closeOutTresholdLevel}
                error={Boolean(errors.closeOutTresholdLevel)}
                message={errors.closeOutTresholdLevel?.message}
              />
            )}
          />
          <Controller
            name="resetLevel"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                sx={CONTROL_CLS}
                label="Reset Level, %"
                type="text"
                warning={dirtyFields.resetLevel}
                error={Boolean(errors.resetLevel)}
                message={errors.resetLevel?.message}
              />
            )}
          />
        </ControlsRow>
      </ControlsContainer>
    </form>
  );
};
