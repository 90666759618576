import { styled, Input, Table, ToggleButtonGroup } from 'react-ui-kit-exante';

export const TableContainer = styled(Table)(() => ({
  '& div[data-test-id="table__body--actions-cell"]': {
    boxShadow: 'none',
  },
}));

export const ControlsInputMultiline = styled(Input)(() => ({
  marginTop: '16px !important',
  width: '100%',

  '& .MuiInputBase-multiline': {
    paddingTop: '20px',
  },
  '& textarea': {
    padding: '0 !important',
  },
}));

export const FormActions = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  justifyContent: 'flex-end',
  marginTop: '24px',
  width: '100%',
}));

export const ToggleButtonGroupContainer = styled(ToggleButtonGroup)(() => ({
  width: '100%',

  '& button': {
    flex: '1 1 auto',
  },
}));
