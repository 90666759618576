import { styled, Input } from 'react-ui-kit-exante';

export const ControlsContainer = styled('div')(({ theme }) => ({
  background: theme.color.bg.primary,
  borderTop: `1px ${theme.color.dropdown.border} solid`,
  minHeight: '80vh',
  padding: '24px',
}));

export const ControlsRow = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
}));

export const ControlsRadioGroup = styled('div')(() => ({
  paddingTop: '16px',
}));

export const ControlsInputMultiline = styled(Input)(() => ({
  marginTop: '16px !important',
  width: '100%',

  '& .MuiInputBase-multiline': {
    paddingTop: '20px',
  },
  '& textarea': {
    padding: '0 !important',
  },
}));

export const ActionsContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
}));
