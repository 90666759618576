import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getAPIHost } from './getAPIHost';
import { getDefaultHeaders, paramsSerializer } from './helpers';
import { getAccessTokenFromCookies, getSessionIdFromCookie } from './session';
import type { TOptionsArguments, TRequestArguments } from './types';

export function apiRequest<T = any>(
  args: TRequestArguments,
  options?: TOptionsArguments,
): Promise<AxiosResponse<T>> {
  const {
    url: originalUrl,
    method = 'GET',
    data,
    headers,
    params,
    cookieType,
  } = args;

  const baseUrl = getAPIHost();
  const fullApiUrl = /^(https?:\/\/)/.test(originalUrl)
    ? originalUrl
    : `${baseUrl}${originalUrl}`;

  let sessionId = getSessionIdFromCookie();

  if (cookieType) {
    sessionId = getSessionIdFromCookie(cookieType);
  }

  const config: AxiosRequestConfig = {
    url: fullApiUrl,
    method,
    data: method !== 'GET' ? data : null,
    headers:
      headers || getDefaultHeaders(sessionId, getAccessTokenFromCookies()),
    params,
    paramsSerializer,
    ...options,
  };

  return axios(config);
}
