import { ICellValue, IColumn } from 'react-ui-kit-exante';

import type { TLiquidationEstimate } from 'types';
import { formatNumberToFinancial } from 'utils';

export const getColumns = (): IColumn<TLiquidationEstimate> => [
  {
    Header: 'Priority',
    accessor: 'index',
    Cell: ({ row: { original } }: ICellValue<TLiquidationEstimate>) =>
      original.index || '',
  },
  {
    Header: 'Account ID',
    accessor: 'accountId',
    minWidth: 200,
    maxWidth: 225,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    Cell: ({ row: { original } }: ICellValue<TLiquidationEstimate>) =>
      original.accountId || '',
  },
  {
    Header: 'Symbol ID',
    accessor: 'asset',
    minWidth: 225,
    maxWidth: 250,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    Cell: ({ row: { original } }: ICellValue<TLiquidationEstimate>) =>
      original.asset || '',
  },
  {
    Header: 'Quantity',
    accessor: 'accountQtty',
    disableFilters: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TLiquidationEstimate>) =>
      original.accountQtty
        ? formatNumberToFinancial(original.accountQtty, 2, 5)
        : '',
  },
  {
    Header: 'Liquidation Qty',
    accessor: 'quantity',
    disableFilters: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TLiquidationEstimate>) =>
      original.quantity ? formatNumberToFinancial(original.quantity, 2, 5) : '',
  },
  {
    Header: 'Margin Delta',
    accessor: 'delta',
    disableFilters: true,
    formatting: 'number',
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TLiquidationEstimate>) =>
      original.delta ? formatNumberToFinancial(original.delta, 0, 0) : '',
  },
  {
    Header: 'Cummulative Margin Delta',
    accessor: 'assetCummulativeDelta',
    disableFilters: true,
    formatting: 'number',
    minWidth: 225,
    maxWidth: 250,
    sortType: 'numeric',
    Cell: ({ row: { original } }: ICellValue<TLiquidationEstimate>) =>
      original.assetCummulativeDelta
        ? formatNumberToFinancial(original.assetCummulativeDelta, 0, 0)
        : '',
  },
  {
    Header: 'Reason',
    accessor: 'reason',
    minWidth: 300,
    maxWidth: 325,
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string) => value || '',
      },
    },
    Cell: ({ row: { original } }: ICellValue<TLiquidationEstimate>) =>
      original.reason || '',
  },
];
