import { IconButton } from 'react-ui-kit-exante';

import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';

import { Container } from './styled';

type RefreshButtonProps = {
  onRefresh: () => void;
  disabled?: boolean;
  loading: boolean;
};

export const RefreshButton = ({
  onRefresh,
  disabled,
  loading = false,
}: RefreshButtonProps) => {
  return (
    <Container>
      <IconButton
        disabled={disabled || loading}
        iconSize={24}
        onClick={onRefresh}
        title="Refresh"
      >
        <RefreshIcon />
      </IconButton>
    </Container>
  );
};
