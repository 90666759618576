import type { TParams, TPostponeState } from 'types';

import { transformParams } from './helpers';
import { PostponeRepository } from './postpone.repository';

export class PostponeService {
  public async fetchPostpones(params: TParams): Promise<TPostponeState> {
    const { data } = await PostponeRepository.fetchPostpones(
      transformParams(params),
    );

    return {
      count: data.count,
      postpones: data.force_close_events,
    };
  }
}
