import { number, object, string } from 'yup';

export const VALIDATION_SCHEMA = object().shape({
  resolution: string().required('Resolution is a required field'),
  internalComment: string().required('Comment is a required field'),
  shiftImmunity: object()
    .shape({
      label: string(),
      value: number(),
    })
    .nullable(),
});
