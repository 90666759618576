import { RiskEventState } from '../types';

export const onFetchPostponeSucceed = (
  state: RiskEventState,
): RiskEventState => ({
  ...state,

  ready: true,

  fetchPostponeStatus: {
    error: null,
    pending: false,
    succeed: true,
  },
});
