import type {
  TForceCloseEvent,
  TForceCloseEventResponse,
  TFormUpdateForceCloseEvent,
  TParams,
  TUpdateForceCloseEvent,
} from 'types';
import { apiRequest } from 'utils';

import {
  FORCE_CLOSE_EVENT_API,
  getForceCloseEventByNumberAPI,
} from './endpoints';

export class ForceCloseEventRepository {
  public static fetchForceCloseEvents(params: TParams) {
    return apiRequest<TForceCloseEventResponse>({
      params,
      url: FORCE_CLOSE_EVENT_API,
    });
  }

  public static fetchForceCloseEventByNumber(number: string) {
    return apiRequest<TForceCloseEventResponse>({
      url: getForceCloseEventByNumberAPI(number),
    });
  }

  public static updateForceCloseEvent(
    number: string,
    payload: TUpdateForceCloseEvent | TFormUpdateForceCloseEvent,
  ) {
    return apiRequest<TForceCloseEvent>({
      url: getForceCloseEventByNumberAPI(number),
      method: 'PUT',
      data: payload,
    });
  }
}
