export const EXAMPLE_OPTIONS = [
  { label: 'one', value: 'one' },
  { label: 'two', value: 'two' },
  { label: 'three', value: 'three' },
];

export const DISABLED_OPACITY = 0.4;

export const SkeletonHeight = {
  small: 6,
  medium: 22,
  large: 22,
};
