import { TCommentModal } from 'pages/RiskEvent/types';

import { RiskEventState } from '../types';

export const onPostponeToggleCommentModal = (
  state: RiskEventState,
  payload: TCommentModal,
): RiskEventState => ({
  ...state,

  commentModal: payload,
});
