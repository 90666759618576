import type { TParams, TSorting } from 'types';
import { convertDateToUTC, transformSorting } from 'utils';

export function transformParams(params: TParams) {
  const paramsClone = { ...params };

  if (paramsClone.sorting) {
    const sortingForApi = transformSorting(paramsClone.sorting as TSorting[]);

    paramsClone.order = sortingForApi.order;
    paramsClone.orderBy = sortingForApi.orderBy;

    delete paramsClone.sorting;
  }

  if (paramsClone.insertedAt) {
    const [from, to] = paramsClone.insertedAt as Date[];

    paramsClone.from = convertDateToUTC(from).toISOString().slice(0, 10);
    paramsClone.to = convertDateToUTC(to).toISOString().slice(0, 10);

    delete paramsClone.insertedAt;
  }

  if (paramsClone.state) {
    paramsClone.statuses = paramsClone.state;

    delete paramsClone.state;
  }

  return paramsClone;
}
