import { initResources } from './resources';
import { addBaseTag } from './utils/addBaseTag';
import { initApp } from './utils/initApp';

addBaseTag();
initResources();

window.REMUS_UI = {
  ...(window.REMUS_UI || {}),
  render: (node: Element) => initApp(node),
};

// Case when rendered not inside run-ui
if (!window.runUIhistoryContainer) {
  initApp();
}
