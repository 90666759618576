export enum Tabs {
  ForceCloseRules = 0,
  ForceCloseEvents = 1,
  Postpones = 2,
}
export const ACTIVE_TAB = Tabs.ForceCloseEvents;

export const ONE_HOUR_IN_SECONDS = 3600;
export const FOREVER = 999999;
export const POSTPONE_OPTIONS = [
  {
    label: 'For 1 Hour',
    value: 1 * ONE_HOUR_IN_SECONDS,
  },
  {
    label: 'For 2 Hours',
    value: 2 * ONE_HOUR_IN_SECONDS,
  },
  {
    label: 'For 4 Hours',
    value: 4 * ONE_HOUR_IN_SECONDS,
  },
  {
    label: 'For 8 Hours',
    value: 8 * ONE_HOUR_IN_SECONDS,
  },
  {
    label: 'For 12 Hours',
    value: 12 * ONE_HOUR_IN_SECONDS,
  },
  {
    label: 'For 24 Hours',
    value: 24 * ONE_HOUR_IN_SECONDS,
  },
  {
    label: 'For 2 Days',
    value: 48 * ONE_HOUR_IN_SECONDS,
  },
  {
    label: 'Forever',
    value: FOREVER,
  },
];
